
<div class="relative">
    <div class="absolute right-0"><button mat-dialog-close ><mat-icon  [icIcon]="icClose"></mat-icon></button></div>
    <div class="flex justify-center h-full p-0 p-gutter select-challenge-sec">
        <div class="relative flex items-center ">

            <form [formGroup]="form">
                <div>
                    <img src="../../../../assets/img/Woman_thinking-pana.svg" class=" think-img">
                    <!-- <h2>Make sure you select the challenge type you want to launch</h2> -->
                    <h2>What type of activity would <br> you like to start?</h2>

                    <div class="mt-5 text-center">
                        <div>
                            <mat-checkbox formControlName="singleChallenge" class="example-margin checkbox-btn"
                                [(ngModel)]="singleChallenge" (change)="changeValue($event,'singleChallenge')">Launch
                            </mat-checkbox>
                            <mat-checkbox formControlName="multiChallenge" class="example-margin checkbox-btn"
                                [(ngModel)]="multiChallenge" (change)="changeValue($event,'multiChallenge')">Add More
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <button mat-raised-button class="continue-btn"
                            [disabled]="singleChallenge == false && multiChallenge == false || isBtnDisabled == true"
                            (click)="formData()"> Continue</button>

                    </div>

                </div>
            </form>

        </div>
    </div>
</div>


 