import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-praise-detail',
  templateUrl: './praise-detail.component.html',
  styleUrls: ['./praise-detail.component.scss']
})
export class PraiseDetailComponent implements OnInit {
  praiseHistroy: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PraiseDetailComponent> 
  ) {

    this.praiseHistroy = data.data;
    console.log("data from dailog",data.data)
   }

  ngOnInit(): void {
  }

}
