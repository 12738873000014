<div class="p-6">
    <div class="flex flex-col items-center justify-center gap-4">
        <img src="assets/img/on-board/congratulation.png" alt="">
        <h5 class="mb-2 text-3xl font-extrabold text-black sm:text-4xl xl:text-5xl">Congratulations</h5>
        <p class="text-lg leading-snug text-center text-black sm:text-2xl">{{this.data}}</p>
        <div class="mt-2 mb-0 text-base font-semibold congratulation-sec" (click)="this.dialogRef.close()">
            Sending you to the dashboard         
            <img src="../../assets/img/on-board/loader.gif" class="w-16">       
        </div>

      
    </div>
</div>
