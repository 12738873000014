<div>
    <div>
        <div class="flex border-b pb-2 border-lightSilver justify-between items-center w-full mb-3">
            <div>
                <h3 class="text-center"> {{this.mode == 'add' ? 'Add Question' : 'Edit Question'}} </h3>
            </div>
            <div class="relative">
                <button class="custom-close-btn" (click)="close()">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                </button>
            </div>
        </div>


        <div class="pt-5">
            <form [formGroup]="this.form" class="relative">
                <div class="flex justify-between gap-2 items-end">
                    <div class="flex flex-col w-full h-24">
                        <label class="pb-2 font-semibold">What do you want to ask? </label>
                        <input class="w-full box-border border rounded border-inputBorder py-3 px-4"
                            placeholder="Enter question here" formControlName="question" />
                        <mat-error *ngIf="form.get('question').hasError('required') && form.get('question').touched">
                            {{'Question is required'}}</mat-error>
                    </div>
                    <div class="min-w-56 pb-5">
                        <mat-select class="w-full box-border border rounded border-inputBorder py-3 px-4"
                            placeholder="Question type" formControlName="questionType">
                            <mat-option [value]="item.id.toString()" *ngFor="let item of questionTypes">
                                {{item.title}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="flex dynamic-height flex-col gap-6 mt-4 w-full">
                    <ng-container *ngIf="this.form.get('questionType').value == '1'">
                        <div formArrayName="options"
                            *ngFor="let optionGroup of this.form['controls'].options['controls']; let j = index">
                            <ng-container [formGroup]="optionGroup">
                                <div class="flex flex-col w-full">
                                    <div class="flex flex-row items-center w-full">
                                        <div class="lex flex-col w-1/2 relative">
                                            <input class="w-full box-border border rounded border-inputBorder py-3 px-4"
                                                placeholder="Put option here" formControlName="optionValue" />
                                            <span class="text-red text-xs absolute left-0" style="top:102%"
                                                *ngIf="optionGroup.get('optionValue').hasError('required') && optionGroup.get('optionValue').touched">
                                                {{'Option is required'}}</span>
                                        </div>
                                        <div class="w-1/2 px-3">
                                            <!-- add new option -->
                                            <span *ngIf="this.options['controls'].length == j + 1"
                                                (click)="onAddNewOption(j)" type="button"
                                                class="text-themeColor  cursor-pointer text-sm font-medium">Add
                                                Option</span>
                                            <!-- remove option -->
                                            <span *ngIf="j > 1 && this.options['controls'].length != j + 1"
                                                (click)="onRemoveOption(j)"
                                                class="text-themeColor cursor-pointer text-sm font-medium">Remove</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.form.get('questionType').value == '2'">
                        <textarea class="w-full box-border border rounded border-inputBorder py-3 px-4" disable
                            [disabled]="true" rows="6" placeholder="User feedback..."
                            formControlName="answer"></textarea>
                    </ng-container>
                </div>

            </form>
        </div>

    </div>



    <mat-dialog-actions align="end">
        <button (click)="onSubmit()"
            [disabled]="this.form.get('questionType').value == '1' ? (this.form.invalid && this.options['controls']?.length < 2) : false"
            mat-flat-button color="primary"
            class="flex items-center pt-1 pb-1 pl-6 pr-6 rounded-lg next-btn">{{this.mode == 'add' ? 'Add' :
            'Update'}}</button>
    </mat-dialog-actions>
</div>