import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from "@angular/material/stepper";
import icRightArrow from "@iconify/icons-ic/keyboard-arrow-right";
import icLeftArrow from "@iconify/icons-ic/keyboard-arrow-left";
import icClose from "@iconify/icons-ic/twotone-close";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthServiceLocal } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipPosition } from '@angular/material/tooltip';
import * as XLSX from "xlsx";
import { SlackMembersDialogComponent } from '../slack-members-dialog/slack-members-dialog.component';
import { LaunchChallengeSuccessfullyDialogComponent } from '../launch-challenge-successfully-dialog/launch-challenge-successfully-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import clock from '@iconify/icons-ic/round-access-time-filled';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { C } from '@angular/cdk/keycodes';
import { emailDomains } from '../utils/systemEnums';


type AOA = any[][];

@Component({
  selector: 'vex-add-new-teams-manager-inivite',
  templateUrl: './add-new-teams-manager-inivite.component.html',
  styleUrls: ['./add-new-teams-manager-inivite.component.scss',
    '../inivated-on-boarding-flow/inivated-on-boarding-flow.component.scss',
    "../../../node_modules/quill/dist/quill.snow.css",
    "../../@vex/styles/partials/plugins/_quill.scss",],
  providers: [
    { provide: Window, useValue: window }],
})
export class AddNewTeamsManagerIniviteComponent implements OnInit {

  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  selectedIndex: any = 0;
  challengeTemplateIndex = 0;

  focusArea: any = [];
  isManagerFocusAreaSelected: any;
  challengeTemplate: any;
  challengeTemplateData: any;
  challengeData: any;
  teamId: any;
  launchScreen: any;
  isNotificationType: any;
  addedChallengeTemplate: any;
  uploadedEmailCount: any;
  slackPayload: any;
  serviceRole: any
  isShowSeeMoreOption: boolean = false;
  isSlackModelOpen: boolean = false;
  isAnySelected: boolean = false;
  isNewTeamSelected: boolean = true
  notificationType: any;
  isNewChallengeOrExisting: boolean = true;





  newWindow: Window;
  emaildata: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "SheetJS.xlsx";

  emailInput = new FormControl("", [Validators.required, Validators.pattern(/^(\s*[\w\.-]+(\+\d+)?@[\w\.-]+\s*,\s*)*[\w\.-]+(\+\d+)?@[\w\.-]+\s*$/)]);
  chipsEmails: any = [];


  currentDate = new Date();
  maxDate = new Date(1735649084000);

  isLinear: boolean = false;
  isOptional: Boolean = true;
  isChallengeEdit: boolean = true;
  slackIntergrationStatus: boolean = false;
  isSlackFlow: boolean = false;
  isVaildDomain: boolean = false;
  recommendedDate = new Date()


  teamNameFormGroup: FormGroup;
  focusHeadCategoryGroup: FormGroup;
  focusAreaFormGroup: FormGroup;
  selectedChallengeFormGroup: FormGroup;
  challengeLaunchDates: FormGroup;
  emailUpload: FormGroup;
  userRolesFormGroup: FormGroup
  weeklyActionGroup: FormGroup
  departmentNameFormGroup: FormGroup

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);

  selectServiceRole: any;

  icRightArrow = icRightArrow;
  icLeftArrow = icLeftArrow;
  icClose = icClose
  clock = clock
  selectedFocus: any
  selectedTeamSize: any;
  serviceRolesList: any;
  selectedTeamId: number;
  isDivVisible: boolean = false;



  focusCategory = [

    {
      id: 2,
      name: "Automate <br> Growth & Development ",
      title: "Automate Growth & Development",
      shortName: "development",
      image: "../../assets/img/inivated-user/simplifyPdp.svg",
      desc: `Actions aligned with your strategic goals`,
    },
    {
      id: 1,
      name: "Build <br> Culture Effortlessly",
      title: "Build Culture Effortlessly",
      shortName: 'culture',
      image: "../../assets/img/inivated-user/buildCulture.svg",
      desc: `Promote desired cultural values`,
    }
  ]

  weeklyAction = [
    { id: 0, shortTitle: 'mon', title: 'Monday', isSelected: false, isEdit: false, notification: '' },
    { id: 1, shortTitle: 'tue', title: 'Tuesday', isSelected: false, isEdit: false, notification: '' },
    { id: 2, shortTitle: 'wed', title: 'Wednesday', isSelected: false, isEdit: false, notification: '' },
    { id: 3, shortTitle: 'thu', title: 'Thursday', isSelected: false, isEdit: false, notification: '' },
    { id: 4, shortTitle: 'fri', title: 'Friday', isSelected: false, isEdit: false, notification: '' }

  ]

  preferredChannel = [
    { title: `We'll inform your teammates about the action` },
    { title: 'Nudge them to complete' },
    { title: 'Send you status reports each Friday' }
  ]

  emailDomains = emailDomains;

  allKpiList: any;
  selectedCount: any;
  teamList: any;
  isChannelTypeBtnDisabled: boolean = false;
  weeklyNotificationsPayload: { weeklyNotifications: any[]; hour: any; minute: any; };

  isActionForEmployeeOrTeam: boolean = true;
  isServiceIdSelected: boolean = false;


  departmentList: any;

  // defaultValue ='Wed Apr 03 2024 09:45:38 GMT+0530 (India Standard Time)';

  defaultTime: any = new Date();




  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddNewTeamsManagerIniviteComponent>,
    public _matDialog: MatDialog,
    private auth: AuthServiceLocal,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private window: Window,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe


  ) {

    console.log("hello in add teams", this.data);



    this.teamNameFormGroup = this._formBuilder.group({
      teamName: [""],
      teamId: [""]
    })


    this.userRolesFormGroup = this._formBuilder.group({
      serviceId: ["", Validators.required],
    });


    this.weeklyActionGroup = this._formBuilder.group({
      time: ["", Validators.required],
    });

    this.departmentNameFormGroup = this._formBuilder.group({
      departmentName: [""],
      departmentId: [""]

    })

    this.focusAreaFormGroup = this._formBuilder.group({
      focusArea: ["", Validators.required],
    });

    this.selectedChallengeFormGroup = this._formBuilder.group({
      selectedChallenge: ["", Validators.required],
    });

    this.challengeLaunchDates = this._formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ["", Validators.required],
    });

    this.emailUpload = this._formBuilder.group({
      file: ["", Validators.required],
    });

    this.getDepartmentList()


    if (this.isNewTeamSelected) {
      this.getUserServiceRoles()
    }
  }

  ngOnInit(): void {

    this.defaultTime.setHours(9, 45); // Set the time to 09:45

    this.matIconRegistry.addSvgIcon(
      "svgCustomArrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/inivated-user/arrow2.svg"
      )
    );


    // get start date and end date for challenge launch
    this.challengeLaunchDates.get("startDate").valueChanges.subscribe((res) => {
      console.log("hello in startdate response ===>", res);

      const endDate = this.addDaysToTimestamp(res, 28); // 4 week 4*7 = 28
      console.log("this is the endDate", new Date(endDate));
      this.challengeLaunchDates.get("endDate").setValue(new Date(endDate));
    });

    const tempUserData = JSON.parse(localStorage.getItem('loggedUser'))?.steps
    this.selectedTeamSize = tempUserData[0]?.teamSize
    this.selectedFocus = tempUserData[2]?.filter(x => x.isSelected === 1)?.map(x => x.name)[0]

    console.log("selectedFocus ===>", this.selectedFocus)

  }

  // add 4 weeks dayes in today's date. if today date is selected for launch challenge.
  addDaysToTimestamp(timestamp, days) {
    const milliseconds = days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
    const newTimestamp = new Date(timestamp);
    newTimestamp.setTime(newTimestamp.getTime() + milliseconds);
    return newTimestamp;
  }

  // set progress bar 
  getCurrentProgressBar(value) {
    return value * 16.66;
  }

  selectionChange(event: any) {
    this.selectedIndex = event.selectedIndex;
  }

  saveTeamData() {
    this.stepper.next();

    if (!this.isNewTeamSelected) {
      this.getFocusArea(1)
    }
  }





  seeMore() {
    this.focusAreaFormGroup.get("focusArea").setValue('')
    this.getFocusArea(2)
    this.isShowSeeMoreOption = !this.isShowSeeMoreOption
  }

  getFocusArea(roleType) {


    let isExistingTeamPayload: any = {
      roleType: roleType,
      isExistingTeam: this.teamNameFormGroup.value.teamId
    }


    this.auth.getFocusAreaList(!this.isNewTeamSelected ? isExistingTeamPayload : roleType).subscribe((res: any) => {
      if (res.status == 200) {

        if (roleType == 1) {

          let tempFocusArea = res.data.kpis.map((element) => ({
            ...element,
            isSelected: false,
          }));


          if (this.isManagerFocusAreaSelected?.id) {
            tempFocusArea.forEach((element: any) => {

              if (element.id == this.isManagerFocusAreaSelected?.id) {
                element['isSelected'] = true
              } else {
                element['isSelected'] = false
              }
            })
            this.focusArea = tempFocusArea;
            this.cd.detectChanges()

          } else {
            this.focusArea = tempFocusArea;
            this.cd.detectChanges()
          }



        } else if (roleType == 2) {
          this.allKpiList = res.data.kpis
          this.selectedCount = res.data.kpis.filter(item => item.isSelected === 1).length;
          this.cd.detectChanges()
        }





      } else {
        // this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }


  selectFocusKpiArea(index) {

    if (this.selectedCount < 3) {
      this.allKpiList[index].isSelected = this.allKpiList[index].isSelected === 1 ? 0 : 1;

      this.selectedCount = this.allKpiList.filter(item => item.isSelected === 1).length;
      this.cd.detectChanges();

    } else if (this.selectedCount === 3 && this.allKpiList[index].isSelected === 1) {
      this.allKpiList[index].isSelected = 0;
      this.selectedCount--;
      this.cd.detectChanges();



    } else {
      this.allKpiList[index].isSelected = 0;
      this.cd.detectChanges();

      this._snackBar.open("You can select 3 options max", "Okay", { duration: 2000 });
    }

  }

  saveKpi() {
    console.log("allKpiList ===>", this.allKpiList)

    let tempFocusKpiSelected = []
    this.allKpiList.forEach(element => {
      if (element.isSelected == 1) {
        tempFocusKpiSelected.push(element.id)
      }
    });



    let payload = {
      focusKpiIds: tempFocusKpiSelected.toString(),
      teamId: this.selectedTeamId
    }

    this.auth.saveMangerFocusArea(payload).subscribe((res: any) => {
      if (res.status == 200) {
        console.log("res data in submit email in board  ===>", res.data);
        this.isShowSeeMoreOption = false;
        this.getFocusArea(1)
        this.cd.detectChanges()
      } else {
        // this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });


  }

  saveFocusCategory(value) {
    this.focusHeadCategoryGroup.get("headCategory").setValue(value);
    if (value) {
      this.getKpiAreaListData();
      this.stepper.next()


    }
  }

  // get kpi area list
  getKpiAreaListData() {

    let params = {
      headCategoryId: this.focusHeadCategoryGroup.value.headCategory.id,
    };
    this.auth.getManagerFocusHeadCategory(params).subscribe((res: any) => {
      if (res.status == 200) {

        let tempFocusArea = res.data.kpis.map((element) => ({
          ...element,
          isSelected: false,
        }));

        this.focusArea = tempFocusArea;
        console.log("kpi area name list ", tempFocusArea);
      } else {
        // this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }


  selectFocusArea(indexValue) {
    this.isManagerFocusAreaSelected = "";
    this.focusArea.forEach((element, index) => {
      if (index == indexValue) {
        element["isSelected"] = true;
        this.focusAreaFormGroup.get("focusArea").setValue(element.name);
        this.isManagerFocusAreaSelected = element;
        this.cd.detectChanges();
      } else {
        element["isSelected"] = false;
      }
    });
  }

  saveFocusArea() {
    this.challengeTemplate = '';
    let params = {
      kpiIds: this.isManagerFocusAreaSelected?.id,
      teamId: this.selectedTeamId
    };

    console.log("we are right ---", params);

    this.auth.saveMangerFocusArea(params).subscribe((res: any) => {
      if (res.status == 200) {

        this.selectedFocus = this.isManagerFocusAreaSelected?.name

        this.getManagerChallengeTemplate(this.isManagerFocusAreaSelected?.id);
        this.getRecommendedDate()
        this.stepper.next();

      } else {
        // this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });

  }

  getManagerChallengeTemplate(id) {
    let params = {
      kpiId: id,
      teamId: this.teamNameFormGroup.get('teamId').value ? this.teamNameFormGroup.get('teamId').value : ""
    };

    console.log("params for save team data  ===>", params);
    this.auth.getChallengeTemplate(params).subscribe((res: any) => {
      if (res.status == 200) {
        console.log("challenge template data ===>", res.data);

        res.data.templates.forEach(element => {
          element['isSelected'] = 0
        });

        this.challengeTemplate = res.data.templates;

        console.log("object", this.challengeTemplate)

      } else {
        this.challengeTemplate = []
        // this._snackBar.open(res.message, "Okay", { duration: 3000});
      }
    });
  }


  challengeDetailsBack() {
    if (!this.isChallengeEdit) {
      this.isChallengeEdit = !this.isChallengeEdit
      this.challengeTemplateIndex = 0
    } else {
      this.challengeTemplateIndex = 0
    }
  }


  next() {

    if (this.challengeTemplate.length != this.challengeTemplateIndex + 1) {
      this.challengeTemplateIndex = this.challengeTemplateIndex + 1;

      if (!this.isChallengeEdit) {
        this.isChallengeEdit = !this.isChallengeEdit
      }
    }
  }

  pre() {
    if (this.challengeTemplateIndex > 0) {
      this.challengeTemplateIndex = this.challengeTemplateIndex - 1;

      if (!this.isChallengeEdit) {
        this.isChallengeEdit = !this.isChallengeEdit
      }
    }
  }


  selectChallenge() {
    console.log(this.challengeTemplate[this.challengeTemplateIndex])

    this.challengeTemplate.forEach(element => {
      if (element.id == this.challengeTemplate[this.challengeTemplateIndex]?.id) {
        element.isSelected == 0 ? element.isSelected = 1 : element.isSelected = 0;

      } else {
        element.isSelected = 0
      }
    })
    this.cd.detectChanges();

    console.log("selectedChallengeFormGroup", this.selectedChallengeFormGroup)


  }

  launchChallenge() {

    this.challengeTemplate.forEach(element => {
      if (element.isSelected == 1) {
        this.selectedChallengeFormGroup.get("selectedChallenge").setValue(this.challengeTemplate[this.challengeTemplateIndex].id)
        this.challengeTemplateData = this.challengeTemplate[this.challengeTemplateIndex];

        console.log("this.challengeTemplate[this.challengeTemplateIndex].notificationMessage", this.challengeTemplate[this.challengeTemplateIndex].notificationMessage)

        this.weeklyAction.forEach(element => {
          element.notification = this.challengeTemplate[this.challengeTemplateIndex].notificationMessage
        });
        this.cd.detectChanges();
        this.stepper.next();
      }
    });

  }


  // notification type slack and email
  sendNotification(type) {
    this.isChannelTypeBtnDisabled = true

    this.isNotificationType = type;
    this.cd.detectChanges();

    if (type == "email") {
      this.isChannelTypeBtnDisabled = false
      this.stepper.next();

    } else {
      // if (this.slackIntergrationStatus) {
      this.getData(true).then(res => {
        console.log("hello in res of", res)

        if (res == true) {
          this.openSlackMembersListing();
        } else {
          const loggedUser: any = JSON.parse(localStorage.getItem("loggedUser"));

          console.log("loggedUser ===>", loggedUser);
          const ordId = localStorage.getItem("orgId")?.length ? localStorage.getItem("orgId") : loggedUser?.organisationId;

          const state = {
            organizationId: ordId,
            isDemo: 1,
            managerId: loggedUser?.id
          };

          console.log("state payload in popup ===>", state);
          this.newWindow = this.window.open(
            `https://slack.com/oauth/v2/authorize?client_id=1179687372869.3192957739207&scope=channels:history,channels:join,channels:read,chat:write,groups:write,im:write,incoming-webhook,mpim:write,team:read,users:read,users:read.email&user_scope=channels:write,im:write,team:read,users:read,users:read.email,chat:write&state=${JSON.stringify(
              state
            )}`,
            "_blank",
            "location=yes,height=900,width=920,scrollbars=yes,status=yes"
          );
          this.isSlackFlow = true;
        }
      })
    }
  }




  // host listener to check slack intergration status 
  @HostListener("window:focus", ["$event"])
  onWindowFocus(event) {

    console.log("event", event);
    if (!this.slackIntergrationStatus && this.isSlackFlow) {
      this.getData(true).then(res => {
        console.log("hello in res of", res)

        if (res == true) {
          this.openSlackMembersListing();
        }
      })
    }
  }

  // get slack intergration status 
  getData(res) {
    return new Promise((resolve, reject) => {

      this.auth.getIntergrationStatus().subscribe((response: any) => {
        if (response.status === 200) {

          this.isSlackModelOpen = true

          this.cd.detectChanges()

          this.slackIntergrationStatus = response.data.isSlackIntegrated;
          return resolve(this.slackIntergrationStatus)

        } else if (response.status === 400) {

          this.slackIntergrationStatus = response.data.isSlackIntegrated;
          return resolve(this.slackIntergrationStatus)

        } else {
          // this._snackBar.open(response.message);
        }
      }, reject)

    });
  }

  // get slack member list 
  openSlackMembersListing() {

    this.auth.getSlackChannelMembersList(this.teamNameFormGroup?.value?.teamId).subscribe((res: any) => {
      if (res.status == 200) {
        console.log("res members list", res.data);
        this.isChannelTypeBtnDisabled = false
        const dailog = this._matDialog.open(SlackMembersDialogComponent, {
          width: "80%",
          height: "80vh",
          panelClass: ['inivatedManagerFlow', "slackMembersWrapper"],
          hasBackdrop: true,
          data: {
            members: res.data.members?.length ? res.data.members : [],
          },
        });

        dailog.afterClosed().subscribe((members) => {
          if (members.length) {
            this.slackPayload = {
              kpiId: this.isManagerFocusAreaSelected.id,
              templateId: this.challengeTemplateData.id,
              title: this.challengeTemplateData.title,
              description: this.challengeTemplateData.description,
              notificationType: 2,
              slackUserIds: [],
              weeklyNotifications: this.weeklyNotificationsPayload.weeklyNotifications,
              hour: this.weeklyNotificationsPayload.hour,
              minute: this.weeklyNotificationsPayload.minute,
              isSingleUser: this.isActionForEmployeeOrTeam ? 1 : 0

            };

            members.forEach((item) => {
              this.slackPayload.slackUserIds.push({
                id: item.id,
                email: item.profile.email,
              });
            });
            this.stepper.next();
            console.log(
              "this is final payload in slack case",
              this.slackPayload
            );

            if (this.isNewTeamSelected) {
              this.slackPayload.teamName = this.teamNameFormGroup.value.teamName;
              this.slackPayload.isNewteam = 1;
              this.slackPayload.serviceId = this.userRolesFormGroup.value.serviceId.id;
            } else {
              this.slackPayload.teamId = this.teamNameFormGroup.value.teamId;
              this.slackPayload.isNewteam = 0
            }
            this.launchTemplate(this.isNotificationType)

          }
        });
      }
    });
  }


  launchTemplate(type) {
    console.log("value in launch template ==>", this.weeklyNotificationsPayload);

    if (type == "email") {
      const filterEmails = [];
      if (this.emaildata.length) {
        this.emaildata.forEach((innerArray, index) => {
          filterEmails[index] = innerArray[0];
        });
      }


      const payload: any = {
        serviceId: this.userRolesFormGroup.value.serviceId.id,
        templateId: this.challengeTemplateData.id,
        title: this.challengeTemplateData.title,
        description: this.challengeTemplateData.description,
        kpiId: this.isManagerFocusAreaSelected.id,
        startDate: new Date(
          this.challengeLaunchDates?.value?.startDate
        ).getTime(),
        endDate: new Date(this.challengeLaunchDates?.value?.endDate).getTime(),
        notificationType: 1,
        emailIds: this.chipsEmails.length ? this.chipsEmails : filterEmails,
        weeklyNotifications: this.weeklyNotificationsPayload.weeklyNotifications,
        hour: this.weeklyNotificationsPayload.hour,
        minute: this.weeklyNotificationsPayload.minute,
        departmentName: this.departmentNameFormGroup.value.departmentName,
        departmentId: this.departmentNameFormGroup.value.departmentId,
        isSingleUser: this.isActionForEmployeeOrTeam ? 1 : 0
      };

      if (this.isNewTeamSelected) {
        payload.teamName = this.teamNameFormGroup.value.teamName;
        payload.isNewteam = 1;
        payload.serviceId = this.userRolesFormGroup.value.serviceId.id;
      } else {
        payload.teamId = this.teamNameFormGroup.value.teamId;
        payload.isNewteam = 0
      }


      console.log("payload data ===>", payload);

      this.auth.launchManagerTemplateChallenge(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.isSlackModelOpen = false
          const congratulationDialog = this._matDialog.open(
            LaunchChallengeSuccessfullyDialogComponent,
            {
              panelClass: 'inivatedManagerFlow',
              width: "800px",
              data: ` You’ve now launched your action. We’ll keep you posted.`
            }
          );
          congratulationDialog.afterClosed().subscribe(() => {
            this.dialogRef.close(res);
          });
        } else {
          // this._snackBar.open(res.message, "OKAY", { duration: 2000 });
        }
      });
    }
    else {

      this.slackPayload.startDate = new Date(this.challengeLaunchDates.get("startDate").value).getTime(),
        this.slackPayload.endDate = new Date(this.challengeLaunchDates.get("endDate").value).getTime();

      this.auth.launchManagerTemplateChallenge(this.slackPayload)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this._snackBar.open(res.message, "OKAY", { duration: 2000 });

            const congratulationDialog = this._matDialog.open(
              LaunchChallengeSuccessfullyDialogComponent,
              {
                panelClass: 'inivatedManagerFlow',
                width: "800px",
                data: ` You’ve now launched your action. We’ll keep you posted.`
              }
            );
            congratulationDialog.afterClosed().subscribe(() => {
              this.dialogRef.close(res);

            });
          } else {
            this._snackBar.open(res.message, "OKAY", { duration: 2000 });
          }
        });
    }
  }


  onFileChange(evt: any) {
    console.log("file changed", this.emailUpload.get("file").value);
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    console.log("target", target.files);
    if (target.files.length > 1) {
      this._snackBar.open("Cannot use multiple files", "OKAY");
      this.uploadedEmailCount = "";
      this.emailUpload.get("file").setValue("");
      // throw new Error('Cannot use multiple files')
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      if (this.chipsEmails.length) {
        this.chipsEmails = [];
      }
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.emaildata = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.emaildata = this.emaildata.filter((element: any) => element != "");
      if (this.emaildata?.length) {
        let pattern: any =
          /^(?!.*@.*@.*$)(?!.*@.*--.*\..*$)(?!.*@.*-\..*$)(?!.*@.*-$)((.*)?@.+(\..{1,11})?)$/;
        function checkEmailValidation(email) {
          return pattern.test(email);
        }

        if (this.emaildata.every(checkEmailValidation)) {
          this._snackBar.open("Success", "OKAY");
          this.uploadedEmailCount = this.emaildata.length;
          console.log("this.data", this.emaildata);
          let emailArr = [];
          this.emaildata.map((element) => {
            emailArr.push(element[0].toString());
          });
          this.emailUpload.get("file").setValue(emailArr);
        } else {
          this.emaildata = [];
          this._snackBar.open("Invalid Email. Select Again", "OKAY");
          this.emailUpload.get("file").setValue("");
          this.uploadedEmailCount = "";
        }
      } else {
        this.emaildata = [];
        this._snackBar.open("No Data Found In Sheet. Select Again", "OKAY");
        this.emailUpload.get("file").setValue("");
        this.uploadedEmailCount = "";
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  saveEmails() {
    const filterEmails: any = [];
    if (this.emaildata.length) {
      this.emaildata.forEach((innerArray, index) => {
        filterEmails[index] = innerArray[0];
      });

      localStorage.setItem("emailId", filterEmails);
    } else {
      localStorage.setItem("emailId", this.chipsEmails);
    }
    console.log("chipsEmails === >", this.chipsEmails);
    console.log("filterEmails === >", filterEmails);

    if (this.chipsEmails?.length || filterEmails?.length) {
      console.log("hello in test ")
      this.launchTemplate(this.isNotificationType)
    } else {
      this.addEmail()
      this.launchTemplate(this.isNotificationType)
    }

  }


  addEmail() {

    if (this.emailInput.invalid) {

      this._snackBar.open('Please enter vaild email', "Okay", { duration: 2000 });
    } else {

      if (this.emailInput.value.includes('@')) {
        var domain = localStorage.getItem('domain')
        domain = domain.replace(/"/g, "")

        console.log("domain", this.emailInput.value, domain)
        if (this.emailInput.value.includes(domain)) {

          var tempEmailAddress = this.emailInput.value.split(',').map((email: string) => email.trim())


          tempEmailAddress.forEach(element => {
            if (element.includes(domain)) {
              this.chipsEmails.push(element);

            } else {
              this.isVaildDomain = true
              this.emailInput.setValue(element);
              this._snackBar.open(element + ' is not an vaild work email', "Okay", { duration: 4000 });
              this.cd.detectChanges()
              return
            }
          });


          this.emailInput.setValue("");
          this.emaildata = [];
          this.emailUpload.get("file").setValue("");
          this.isVaildDomain = false;
          this.cd.detectChanges();


        } else {
          this.isVaildDomain = true
          this._snackBar.open('Please enter vaild work email', "Okay", { duration: 2000 });
          this.cd.detectChanges()
          return


        }
      }
    }

    console.log("hello in add email ---->", this.chipsEmails)

  }

  onRemoveEmail(i) {
    this.chipsEmails.splice(i, 1);
  }


  getRecommendedDate() {
    console.log("hello in recommendate ")
    if (this.isNewTeamSelected) {
      this.auth.getRecommendStartDate().subscribe((response: any) => {
        if (response.status == 200) {
          this.recommendedDate = response.data.recommendedDate;
        } else {
          this._snackBar.open(response.message, "Okay", { duration: 2000 });
        }
      });
    } else {
      let payload = {
        teamIds: this.teamNameFormGroup.value.teamId
      }
      this.auth.getStartDateForTeamSelection(payload).subscribe((response: any) => {
        if (response.status == 200) {
          this.recommendedDate = response.data.startDate;
          this.currentDate = new Date(response.data.startDate);

        } else {
          this._snackBar.open(response.message, "Okay", { duration: 2000 });
        }
      });
    }
  }

  onSave(eventData) {
    this.challengeTemplate[this.challengeTemplateIndex].description = eventData?.target?.innerHTML
  }


  getDepartmentList() {
    this.auth.getDepartmentList().subscribe((response: any) => {
      if (response.status === 200) {

        this.departmentList = response.data

        this.cd.detectChanges()
      } else {
        this.departmentList = []
        // this._snackBar.open(response.message);
      }
    });
  }


  getTeamsData(departmentId: any) {

    this.auth.getManagerCreatedTeams(departmentId).subscribe((response: any) => {
      if (response.status === 200) {

        this.teamList = response.data.teams

        this.cd.detectChanges()
      } else {
        this.teamList = []
        // this._snackBar.open(response.message);
      }
    });
  }

  // selection change of team
  teamOnChange(value) {
    this.selectedTeamId = value;

  }

  onDepartmentChange(value) {

    this.getTeamsData(value)
  }

  onDepartmentSelect(value) {
    this.departmentNameFormGroup.get('departmentName').setValue(value.title)
  }

  getNotification(data) {
    this.notificationType = data.notificationType;

    console.log("data in get notification  ===> ", data)

    if (data.isServiceSelected == 0) {
      this.isServiceIdSelected = true;
      this.cd.detectChanges()

    }
    else if (data.isServiceSelected == 1) {
      this.isServiceIdSelected = false
      this.cd.detectChanges()
    }
  }

  switchTeamType(type) {
    if (type == 'existingTeam') {
      this.isNewTeamSelected = false;

      this.teamNameFormGroup.reset()
    }
    else {
      this.isNewTeamSelected = true
      this.teamNameFormGroup.reset()
      this.getUserServiceRoles()
    }
  }

  switchDepartment(type) {
    if (type == "existing") {
      this.isNewChallengeOrExisting = false
      this.departmentNameFormGroup.reset()
      this.cd.detectChanges()
    } else {
      this.isNewChallengeOrExisting = true
      this.departmentNameFormGroup.reset()
      this.cd.detectChanges()

    }
  }

  selectRoles(indexValue) {
    this.serviceRolesList.forEach((element, index) => {
      if (index == indexValue) {
        element["isSelected"] = true
        this.userRolesFormGroup.get("serviceId").setValue(element);
        localStorage.setItem('serviceRole', JSON.stringify(element))
        this.selectServiceRole = JSON.parse(localStorage.getItem('serviceRole'))?.name

      } else {
        element["isSelected"] = false;
      }
    });

    this.cd.detectChanges();
  }

  getUserServiceRoles() {
    this.auth.getServiceList().subscribe((res: any) => {
      if (res.status == 200) {
        const tempServiceRoleSelected = JSON.parse(localStorage.getItem('serviceRole'))
        this.selectServiceRole = JSON.parse(localStorage.getItem('serviceRole'))?.name

        if (tempServiceRoleSelected) {
          res.data.service.forEach(element => {
            if (element.id == tempServiceRoleSelected.id) {
              element["isSelected"] = true;
              this.userRolesFormGroup.get("serviceId").setValue(element);
            } else {
              element["isSelected"] = false;
            }

          })
          this.serviceRolesList = res.data.service
          this.cd.detectChanges();
        } else {
          this.serviceRolesList = res.data.service;
        }

        this.cd.detectChanges();
      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  saveServiceRole() {
    let params = {
      serviceId: this.userRolesFormGroup.value.serviceId.id,
    };

    this.auth.storeServiceRole(params).subscribe((res: any) => {
      if (res.status == 200) {

        this.selectedFocus = this.userRolesFormGroup.value?.serviceId?.name
        this.getFocusArea(1)
        this.stepper.next();

      } else {
        this._snackBar.open(res.message, "Okay", { duration: 3000 });
      }
    });
  }

  launchTemplateWithoutTeam() {

    let payload = {
      isNewteam: 0,
      teamId: this.teamNameFormGroup.value.teamId,
      kpiId: this.isManagerFocusAreaSelected.id,
      templateId: this.challengeTemplateData.id,
      title: this.challengeTemplateData.title,
      description: this.challengeTemplateData.description,
      startDate: new Date(
        this.challengeLaunchDates?.value?.startDate
      ).getTime(),
      endDate: new Date(this.challengeLaunchDates?.value?.endDate).getTime(),
      notificationType: this.notificationType,
      emailIds: [],
      slackUserIds: [],
      weeklyNotifications: this.weeklyNotificationsPayload.weeklyNotifications,
      hour: this.weeklyNotificationsPayload.hour,
      minute: this.weeklyNotificationsPayload.minute,
      departmentName: this.departmentNameFormGroup.value.departmentName,
      departmentId: this.departmentNameFormGroup.value.departmentId,

      isSingleUser: this.isActionForEmployeeOrTeam ? 1 : 0
    };



    this.auth.launchManagerTemplateChallenge(payload).subscribe((res: any) => {
      if (res.status == 200) {
        this._snackBar.open(res.message, "OKAY", { duration: 2000 });

        const congratulationDialog = this._matDialog.open(
          LaunchChallengeSuccessfullyDialogComponent,
          {
            panelClass: 'inivatedManagerFlow',
            width: "800px",
            data: ` You’ve now launched your action. We’ll keep you posted.`
          }
        );
        congratulationDialog.afterClosed().subscribe(() => {
          this.dialogRef.close(res);

        });
      } else {
        this._snackBar.open(res.message, "OKAY", { duration: 2000 });
      }
    })

  }


  selectDay(data) {
    console.log("hello in select day", data)


    this.weeklyAction.forEach(element => {
      if (element.id == data.id) {
        element.isSelected == false ? element.isSelected = true : element.isSelected = false
      }
    })

    this.cd.detectChanges();



    this.isAnySelected = this.weeklyAction.some(obj => obj.isSelected === true);

    this.cd.detectChanges();

    if (!this.isAnySelected) {
      this.weeklyAction.forEach(obj => {
        if (obj.isSelected === undefined || obj.isSelected === false) {
          this.isAnySelected = false;
        }
      });
      this.cd.detectChanges();

    }

    console.log("isAnySelected ==>", this.isAnySelected)

  }

  customize(data) {

    this.weeklyAction.forEach(element => {
      if (element.id == data.id) {
        return element.isEdit ? element.isEdit = false : element.isEdit = true
      }
    })

    this.cd.detectChanges()
  }

  sendWeeklyNotification() {

    let weeklyNotifications = []
    const tempWeeklyNotification = this.weeklyAction.filter(item => item.isSelected)

    weeklyNotifications = tempWeeklyNotification.map(obj => {
      return {
        day: obj.id,
        notification: obj.notification
      };
    });


    // console.log("localStorage.getItem('challengeDetails')",)

    this.weeklyNotificationsPayload = {
      "weeklyNotifications": weeklyNotifications,
      "hour": this.getHourMins(this.weeklyActionGroup.value.time).hours,
      "minute": this.getHourMins(this.weeklyActionGroup.value.time).minutes
    }
    console.log("weeklyAction", this.weeklyNotificationsPayload)

    this.stepper.next()

  }


  getHourMins(data) {



    // Extract hours and minutes
    var hours = data.getHours();
    var minutes = data.getMinutes();

    // Formatting the hours and minutes
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Constructing the time string
    return { hours, minutes }

  }

  isSubmitActionBtnDisabled(): boolean {


    if (!this.weeklyActionGroup.value.time) {
      return true;
    }


    if (this.weeklyAction.some(x => x.isSelected)) {
      return false;
    }

    return true;
  }
  showDiv() {
    this.isDivVisible = true;
  }

  hideDiv() {
    this.isDivVisible = false;
  }

  isPreviewBtnDisabled() {
    return this.weeklyAction.some(x => x.isSelected)
  }

  switchEmployeeType(type) {
    if (type == "team") {
      this.isActionForEmployeeOrTeam = false;
      this.teamNameFormGroup.reset();
      this.departmentNameFormGroup.reset();

    } else {
      this.isActionForEmployeeOrTeam = true;
      this.teamNameFormGroup.reset()
      this.departmentNameFormGroup.reset();

    }
    this.cd.detectChanges();
  }

}



