<div class="w-full toolbar px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <!-- <div *ngIf="breadCrumbsData" class="flex flex-row items-center">
    <h1 class="m-0 font-medium subheading-2 ltr:pr-3 rtl:pl-3 ltr:border-r rtl:border-l ltr:mr-3 rtl:ml-3">{{breadCrumbsData?.title | translate}}</h1>
    <vex-breadcrumbs [crumbs]="breadCrumbsData?.crumbs"></vex-breadcrumbs>
  </div> -->

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="block ltr:mr-4 rtl:ml-4"
     fxLayout="row"
     fxLayoutAlign="start center">
    <img class="w-8 select-none" src="assets/img/demo/logo.svg">
    <h1 [fxHide]="mobileQuery" class="m-0 select-none title ltr:pl-4 rtl:pr-4">Empida</h1>
  </a>

<!--  <button *ngIf="isHorizontalLayout$ | async"-->
<!--          [fxHide]="mobileQuery"-->
<!--          [matMenuTriggerFor]="addNewMenu"-->
<!--          color="primary"-->
<!--          mat-raised-button-->
<!--          type="button">ADD NEW-->
<!--  </button>-->

<!--  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">-->
<!--    <button mat-menu-item>-->
<!--      <mat-icon [icIcon]="icPersonAdd"></mat-icon>-->
<!--      <span>Add Contact</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon [icIcon]="icDoneAll"></mat-icon>-->
<!--      <span>Add Task</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon [icIcon]="icAssignmentTurnedIn"></mat-icon>-->
<!--      <span>Add Project</span>-->
<!--    </button>-->

<!--    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>-->
<!--      <mat-icon [icIcon]="icBallot"></mat-icon>-->
<!--      <span>Add Document</span>-->
<!--    </button>-->
<!--  </mat-menu>-->

<!--  <mat-menu #documentMenu="matMenu">-->
<!--    <button mat-menu-item>-->
<!--      <mat-icon [icIcon]="icDescription"></mat-icon>-->
<!--      <span>Add Quote</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon [icIcon]="icAssignment"></mat-icon>-->
<!--      <span>Add Invoice</span>-->
<!--    </button>-->

<!--    <button mat-menu-item>-->
<!--      <mat-icon [icIcon]="icReceipt"></mat-icon>-->
<!--      <span>Add Receipt</span>-->
<!--    </button>-->
<!--  </mat-menu>-->

<!--  <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">-->
<!--    <button *ngIf="isHorizontalLayout$ | async"-->
<!--            [fxHide]="mobileQuery"-->
<!--            (click)="openMegaMenu(megaMenuOriginRef)"-->
<!--            color="primary"-->
<!--            mat-button-->
<!--            type="button">MEGA MENU-->
<!--      <ic-icon [icon]="icArrowDropDown" class="ltr:-mr-1 rtl:-ml-1" inline="true"></ic-icon>-->
<!--    </button>-->
<!--  </div>-->


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [fxHide]="mobileQuery"
       class="px-gutter"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="start center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span fxFlex></span>

  <div class="flex items-center -mx-1">
<!--    <div class="px-1">-->
<!--      <button (click)="openSearch()" mat-icon-button type="button">-->
<!--        <mat-icon [icIcon]="icSearch"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->

<!--    <div class="px-1">-->
<!--      <vex-toolbar-notifications></vex-toolbar-notifications>-->
<!--    </div>-->

<!--    <div class="px-1">-->
<!--      <button (click)="openQuickpanel()" mat-icon-button type="button">-->
<!--        <mat-icon [icIcon]="icBookmarks"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->

<!--    <div class="px-1">-->
<!--      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">-->
<!--        <mat-icon [icIcon]="selectedIcon"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->

    <div class="px-1" >
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button (click)="setLanguage(usFlag, language.English)" mat-menu-item>
        <mat-icon [icIcon]="usFlag"></mat-icon>
        <span>English</span>
      </button>

      <button (click)="setLanguage(norwayFlag, language.Norway)" mat-menu-item>
        <mat-icon [icIcon]="norwayFlag"></mat-icon>
        <span>Norway</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
