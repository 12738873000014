<form (ngSubmit)="save()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 *ngIf="mode=='update'"
          class="m-0 headline"
          fxFlex="auto">  {{defaults.type=='update'?'Update Category':'Reuse Category'}}   </h2>
      <h2 *ngIf="mode=='create'"
          class="m-0 headline"
          fxFlex="auto">Add Category</h2>
  
      <button class="text-secondary" (click)="dailogClose()"mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
    </div>
  
    <mat-divider class="-mx-6 text-border"></mat-divider>
<!-- 
    <div class="flex flex-col mt-6 sm:flex-row">

      
    </div>
   -->
    <mat-dialog-content >
      <div class="flex flex-col mt-4 sm:flex-row">
      <mat-form-field class="flex-auto full-width-field">
        <mat-label>Head Category Name </mat-label>
        <mat-select required formControlName="headCategory" 
        (selectionChange)="onSelectHeadCategory($event)">
        <mat-option *ngFor="let category of wellnessHeadCategoryList;" [value]="category.value">
          {{category.name}}
        </mat-option>
      </mat-select>
        <mat-error *ngIf="form.get('headCategory').hasError('required')">This field is required</mat-error>
      </mat-form-field>

      </div>
      <div class="flex flex-col sm:flex-row">
        <mat-form-field class="flex-auto full-width-field">
          <mat-label>Category name (English)</mat-label>
          <input #name cdkFocusInitial formControlName="name" matInput required>
          <mat-error *ngIf="form.get('name').hasError('required')">This field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row">
        <mat-form-field class="flex-auto ">
          <mat-label>Add another language </mat-label>
          <mat-select formControlName="language">
            <mat-option [value]="language.value" *ngFor="let language of languageList;">
              {{language.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

       
      </div>

      <div class ="flex flex-col sm:flex-row" *ngIf="this.form.get('language').value =='NO'">
        <mat-form-field class="flex-auto full-width-field" >
          <mat-label>Category name  (Norwegian)</mat-label>
          <input #name_nb formControlName="name_nb" matInput >
          <mat-error *ngIf="form.get('name_nb').hasError('required')">This field is required</mat-error>
        </mat-form-field>
      </div>
  
      <!-- <div class="flex flex-col sm:flex-row">
        <mat-form-field class="flex-auto">
          <mat-label> Select Organisation</mat-label>
          <mat-select (selectionChange)="checkValue($event)" [multiple]="isCreateMode()" required formControlName="organisationId" [disabled]="isUpdateMode() && defaults.type=='update'">
            <mat-option *ngFor="let organisation of organisationList;" [value]="organisation.id">
              {{organisation.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('organisationId').hasError('required')">This field is required</mat-error>
        </mat-form-field>
      </div> -->
  
      <div class="flex flex-col sm:flex-row">
        <mat-form-field class="flex-auto">
          <mat-label>Start Date</mat-label>
          <input (dateChange)="startDateChangeVal($event)"  [matDatepickerFilter]="weekendStartDatesFilter" [min]="TodayDate" matInput [matDatepicker]="startDate"
                 formControlName="startDate" (focus)="startDate.open()" (click)="startDate.open()" required>
          <mat-datepicker #startDate></mat-datepicker>
          <mat-error *ngIf="form.get('startDate').hasError('required')">This field is required</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-auto sm:ml-6">
          <mat-label>End Date</mat-label>
          <input [min]="MaxEndDate" matInput [matDatepickerFilter]="weekendsDatesFilter" [matDatepicker]="endDate" formControlName="endDate" (click)="endDate.open()" (focus)="endDate.open()" required>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="form.get('endDate').hasError('required')">This field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row"  *ngIf="allWellNess">
        <mat-checkbox #isWellNessSelected formControlName="isWellNessSelected" color="primary" class="mb-2"> Do you want select all learning ?   </mat-checkbox>
      </div>
  
      <div class="mb-2 row " *ngIf="allWellNess">
        <label class="font-weight-bold"> Select learning type </label>
        <div  formArrayName="wellness"   *ngFor="let wellness of  addWellnessArray.controls; let  i=index"  >
         <div [formGroupName]="i" class="flex mt-2 item-center" fxLayoutGap="10px">
          <mat-label fxFlex="30" > {{allWellNess[i].title}}</mat-label>
              <input type="hidden" [value]="allWellNess[i].id"  formControlName="wellnessIdVal">
            <mat-form-field fxFlex="70" class="wellnessCheckbox" >
            <mat-label >Date</mat-label>
            <input  [min]="TodayDateNew" matInput [matDatepicker]="startDate1"
                   formControlName="startDate1" (focus)="startDate1.open()" (click)="startDate1.open()" [required]="form.value.isWellNessSelected==true">
            <mat-datepicker #startDate1></mat-datepicker>
  
          </mat-form-field>
  
         </div>
  
        </div>
      </div>
  
      <div class="overflow-hidden">
        <div class="mb-1"> Image </div>
        <button type="button" class="text-blue wellness-image-btn" (click)="openGalleryModal('wellnessImage')" *ngIf="!isReuse">Browse  Image</button>
        <!-- <input *ngIf="defaults.type!=='reuse'" type="file" id="logo" (change)="readUrl($event)" required accept="image/*"> -->
        <div *ngIf="form.invalid && form.get('logo').touched">
          <mat-error *ngIf="form.get('logo').hasError('required')">This field is required</mat-error>
        </div>
        <div class="w-full mt-2 mb-2">
          <img [src]="logoUrl"  *ngIf="logoUrl" class="object-fit-cover" style="height: 100px; width: 100px">
        </div>
      </div>
  
  
    </mat-dialog-content>
  
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-actions align="end">
      <button mat-button (click)="dailogClose()" type="button">CANCEL</button>
      <button *ngIf="isCreateMode()" color="primary" mat-button type="submit" [disabled]="(form.invalid || form.pristine) || form.pending">ADD CATEGORY</button>
      <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit" [disabled]="form.invalid && isReuse"> {{defaults.type=='update'?'UPDATE CATEGORY':'REUSE CATEGORY'}} </button>
    </mat-dialog-actions>
  </form>
  
  
  