import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icChecked from '@iconify/icons-ic/twotone-check-circle-outline';
import { AuthServiceLocal } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject } from 'rxjs';
import { GalleryModalService } from './gallery-modal.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
    selector: 'vex-customer-create-update',
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit, AfterViewInit {

    static id = 100;
    form: FormGroup;
    mode: 'photo'| 'wellnessImage'| 'rewardPhoto' = 'photo';
    icClose = icClose;
    icChecked = icChecked;
    rewardPhotoUrl: any;
    photoUrl: any;
    wellnessImageUrl: any;
    photo: any;
  

    //   challengeImagesArray = [




    //     ];
    challengeImagesArray = [
        {
            localPath: 'assets/img/cover-images/img1.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/adventure-backlit-dawn-dusk-207896.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/aerial-photo-of-buildings-and-roads-681335.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img3.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/aerial-photography-of-metropolitan-area-2100018.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img4.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/alp-clouds-dolomites-hill-461956.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img5.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/america-architecture-bay-boat-208745.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img6.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/architecture-bridge-brooklyn-bridge-buildings-421927.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img7.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/man-running-beside-mirror-wall-1777792.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img8.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/men-s-black-suit-70361.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img9.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/ocean-wave-1656579.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img10.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/photo-of-woman-wearing-pink-sports-shoes-walking-1556710.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img11.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/road-landscape-mountains-nature-63324.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img12.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/two-man-hiking-on-snow-mountain-869258.jpg'
        },
        {
            localPath: 'assets/img/cover-images/img13.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/woman-girl-silhouette-jogger-40751.jpg'
        },
        {
            localPath: 'assets/img/cover-images/bottle2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Bottle2.jpg'
        },
        {
            localPath: 'assets/img/cover-images/bottle3.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Bottle3.jpg'
        },
        {
            localPath: 'assets/img/cover-images/counting1.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Counting1.jpg'
        },
        {
            localPath: 'assets/img/cover-images/counting2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Counting2.jpg'
        },
        {
            localPath: 'assets/img/cover-images/counting4.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Counting4.jpg'
        },
        {
            localPath: 'assets/img/cover-images/pushups1.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Pushups1.jpg'
        },
        {
            localPath: 'assets/img/cover-images/pushups2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Pushups2.jpg'
        },
        {
            localPath: 'assets/img/cover-images/sleep1.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Sleep1.jpg'
        },
        {
            localPath: 'assets/img/cover-images/sleep2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Sleep2.jpg'
        },
        {
            localPath: 'assets/img/cover-images/sleep3.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Sleep3.jpg'
        },
        {
            localPath: 'assets/img/cover-images/water1.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Water1.jpg'
        },
        {
            localPath: 'assets/img/cover-images/water2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Water2.jpg'
        },
        {
            localPath: 'assets/img/cover-images/water4.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Water4.jpg'
        },
        {
            localPath: 'assets/img/cover-images/water5.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Water5.jpg'
        },
        {
            localPath: 'assets/img/cover-images/QuizCoverphoto.png',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/QuizCoverphoto.png'
        },
        {
            localPath: 'assets/img/cover-images/pexels-andrea-piacquadio-3763885.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/pexels-andrea-piacquadio-3763885.jpg'
        },
        {
            localPath: 'assets/img/cover-images/Coffee_break.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Coffee_break.jpg'
        },
        {
            localPath: 'assets/img/cover-images/Grateful.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Grateful.jpg'
        },
        {
            localPath: 'assets/img/cover-images/Screentime1.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Screentime1.jpg'
        },
        {
            localPath: 'assets/img/cover-images/ThommessenImage1.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/ThommessenImage1.jpeg'
        },
        {
            localPath: 'assets/img/cover-images/Thommessenm2.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Thommessenm2.jpeg'
        },
        {
            localPath: 'assets/img/cover-images/Talkmore.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Talkmore.jpeg'
        },
        {
            localPath: 'assets/img/cover-images/MEDIEBANK-52_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/MEDIEBANK-52_1080.jpg'
        },

        {
            localPath: 'assets/img/cover-images/MEDIEBANK-51_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/MEDIEBANK-51_1080.jpg'
        },
        {
            localPath: 'assets/img/cover-images/_GR45377_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/_GR45377_1080.jpg'
        },

        {
            localPath: 'assets/img/cover-images/_GR65111_1080.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/_GR65111_1080.jpg'
        },
        {
            localPath: 'assets/img/cover-images/DSC_2492_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/DSC_2492_1080.jpg'
        },

        {
            localPath: 'assets/img/cover-images/DSC_2685_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/DSC_2685_1080.jpg'
        },

        {
            localPath: 'assets/img/cover-images/DSC_5458-2_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/DSC_5458-2_1080.jpg'
        },

        {
            localPath: 'assets/img/cover-images/MEDIEBANK-22_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/MEDIEBANK-22_1080.jpg'
        },
        {
            localPath: 'assets/img/cover-images/MEDIEBANK-20_1080.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/MEDIEBANK-20_1080.jpg'
        },
        {
            localPath: 'assets/img/cover-images/Hoegh-Grace-(1)-thumbnail.jpeg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge/Hoegh-Grace-(1)-thumbnail.jpeg'
        },
        {
            localPath: 'assets/img/cover-images/finn_10959c.jpg',
            url: 'https://empida.s3.us-west-2.amazonaws.com/challenge/finn_10959c.jpg'
        },
        {
            localPath: 'assets/img/cover-images/finn_20141c.jpg',
            url: 'https://empida.s3.us-west-2.amazonaws.com/challenge/finn_20141c.jpg'
        },
        {
            localPath: 'assets/img/cover-images/finn_20290c.jpg',
            url: 'https://empida.s3.us-west-2.amazonaws.com/challenge/finn_20290c.jpg'
        },
        {
            localPath: 'assets/img/challengeImages/eye_contact_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/eye_contact_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/small_talk_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/small_talk_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/be_accountable_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/be_accountable_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/be_bold_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/be_bold_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/belonging_illustraion.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/belonging_illustraion.png'
        },
        {
            localPath: 'assets/img/challengeImages/learn_together_illustraion.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/learn_together_illustraion.png'
        },
        {
            localPath: 'assets/img/challengeImages/compliment_co-worker_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/compliment_co-worker_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/smile_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/smile_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/allstar_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/allstar_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/say_yes_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/say_yes_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/energize_others_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/energize_others_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/cheers_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/cheers_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/keep_it_tidy_illustratin.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/keep_it_tidy_illustratin.png'
        },
        {
            localPath: 'assets/img/challengeImages/show_your_passion_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/show_your_passion_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/ask_for_feedback_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/ask_for_feedback_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/be_positive_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/be_positive_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/boundaries_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/boundaries_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/plan_your_day_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/plan_your_day_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/make_someone_smile_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/make_someone_smile_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/double_7_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/double_7_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/say_thank_you_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/say_thank_you_illustration.png'
        },
        // {
        //     localPath: 'assets/img/challengeImages/How's_it_going_illustration.png',
        //     url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/How's_it_going_illustration.png'
        // },
        {
            localPath: 'assets/img/challengeImages/10_goals_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/10_goals_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/delegate_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/delegate_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/declutter_and_donate_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/declutter_and_donate_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/green_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/green_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/meditate_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/meditate_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/positive_challenege_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/positive_challenege_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/share_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/share_illustration.png'
        }, {
            localPath: 'assets/img/challengeImages/energyshake_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/energyshake_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/wastewalk_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/wastewalk_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/enjoy_nature_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/enjoy_nature_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/stairmageddon_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/stairmageddon_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/help_colleague_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/help_colleague_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/give_attention_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/give_attention_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/act_of_kindness_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/act_of_kindness_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/calming_break_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/calming_break_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/share_your_wisdom_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/share_your_wisdom_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/a_good_thing_a_day_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/a_good_thing_a_day_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/syng_and_dance_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/syng_and_dance_illustration.png'
        }, {
            localPath: 'assets/img/challengeImages/3_I_1_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/3_I_1_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/stress_management_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/stress_management_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/get_smart_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/get_smart_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/lol_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/lol_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/read_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/read_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/lights_out_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/lights_out_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/stand_up_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/stand_up_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/caffeine_free_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/caffeine_free_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/be_present_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/be_present_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/face_a_fear_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/face_a_fear_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/sleep_without_phone_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/sleep_without_phone_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/me_time_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/me_time_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/fresh_exercise_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/fresh_exercise_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/daily_30_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/daily_30_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/walk_bike_run_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/walk_bike_run_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/Emoji_rebus_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/Emoji_rebus_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/scavenger_hunt_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/scavenger_hunt_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/goalgetter_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/goalgetter_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/early30_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/early30_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/plank_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/plank_illustration.png'
        },
        // {
        //     localPath: 'assets/img/challengeImages/trippel_illustration.png',
        //     url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/trippel_illustration.png'
        // },
        {
            localPath: 'assets/img/challengeImages/healthy_meals_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/healthy_meals_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/stretch_out__illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/stretch_out__illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/the_price_right_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/the_price_right_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/level_up_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/level_up_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/Healthy_snacks__illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/Healthy_snacks__illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/complete5k_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/complete5k_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/1_minute_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/1_minute_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/learn_what_others_do_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/learn_what_others_do_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/digital_pet_therapy_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/digital_pet_therapy_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/call_a_friend_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/call_a_friend_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/Tech_free_hour_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/Tech_free_hour_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/meme_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/meme_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/be_a_friend_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/be_a_friend_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/set_a_goal_together_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/set_a_goal_together_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/10_new_recipes_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/10_new_recipes_illustration.png'
        }, {
            localPath: 'assets/img/challengeImages/favourites_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/favourites_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/counting_illustration_1.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/counting_illustration_1.png'
        },
        {
            localPath: 'assets/img/challengeImages/screen_time_illustration_1.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/screen_time_illustration_1.png'
        },
        {
            localPath: 'assets/img/challengeImages/water_illustration_1.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/water_illustration_1.png'
        },
        {
            localPath: 'assets/img/challengeImages/trippel.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/trippel.png'
        },
        {
            localPath: 'assets/img/challengeImages/the_delegate_tasks.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/the_delegate_tasks.png'
        },
        {
            localPath: 'assets/img/challengeImages/teams_of_two.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/teams_of_two.png'
        },
        {
            localPath: 'assets/img/challengeImages/syng_and_dance.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/syng_and_dance.png'
        },
        {
            localPath: 'assets/img/challengeImages/someone_writing_positive_thing.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/someone_writing_positive_thing.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/someone_exercises_mindful_breathing.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/someone_exercises_mindful_breathing.png'
        },
        {
            localPath: 'assets/img/challengeImages/meat_free.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/meat_free.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/how_its_going.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/how_its_going.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/enjoy_nature.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/enjoy_nature.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/grow_your_passion.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/grow_your_passion.png'
        },
        {
            localPath: 'assets/img/challengeImages/green_environment.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/green_environment.png'
        },
        {
            localPath: 'assets/img/challengeImages/energy_shake.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/energy_shake.png'
        },
        {
            localPath: 'assets/img/challengeImages/applause.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/applause.png'
        },



        {
            localPath: 'assets/img/challengeImages/gratitude_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/gratitude_illustration.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/champion_champions_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/champion_champions_illustration.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/activity_challenge_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/activity_challenge_illustration.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/walking_running_challenge_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/walking_running_challenge_illustration.png'
        }, 
        {
            localPath: 'assets/img/challengeImages/push_ups_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/push_ups_illustration.png'
        },
        {
            localPath: 'assets/img/challengeImages/olympic_challenge_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/olympic_challenge_illustration.png'
        },
        


        {
            localPath: 'assets/img/challengeImages/accountability_aces.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/accountability_aces.png'
        },
        {
            localPath: 'assets/img/challengeImages/call_out_corral.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/call_out_corral.png'
        },
        {
            localPath: 'assets/img/challengeImages/challenge_changeover.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/challenge_changeover.png'
        },
        {
            localPath: 'assets/img/challengeImages/champion_chat.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/champion_chat.png'
        },
        {
            localPath: 'assets/img/challengeImages/circular_acts.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/circular_acts.png'
        },
        {
            localPath: 'assets/img/challengeImages/commitment_card_shuffle.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/commitment_card_shuffle.png'
        },
        {
            localPath: 'assets/img/challengeImages/community_catch.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/community_catch.png'
        },
        {
            localPath: 'assets/img/challengeImages/consideration_canvas.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/consideration_canvas.png'
        },
        {
            localPath: 'assets/img/challengeImages/contrstutive_coalition.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/contrstutive_coalition.png'
        },
        {
            localPath: 'assets/img/challengeImages/empathy_map.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/empathy_map.png'
        },
        {
            localPath: 'assets/img/challengeImages/empty_engine.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/empty_engine.png'
        },
        {
            localPath: 'assets/img/challengeImages/feedback_fiesta.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/feedback_fiesta.png'
        },
        {
            localPath: 'assets/img/challengeImages/gratitude_graffiti.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/gratitude_graffiti.png'
        },
        {
            localPath: 'assets/img/challengeImages/kudos_coffee.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/kudos_coffee.png'
        },
        {
            localPath: 'assets/img/challengeImages/leadership_lighthouse.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/leadership_lighthouse.png'
        },
        {
            localPath: 'assets/img/challengeImages/mirroring_circle.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/mirroring_circle.png'
        },
        {
            localPath: 'assets/img/challengeImages/note_of_appreciation.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/note_of_appreciation.png'
        },
        {
            localPath: 'assets/img/challengeImages/objective_oracle.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/objective_oracle.png'
        },
        {
            localPath: 'assets/img/challengeImages/one_voice.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/one_voice.png'
        },
        {
            localPath: 'assets/img/challengeImages/positive_ping_pong.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/positive_ping_pong.png'
        },
        {
            localPath: 'assets/img/challengeImages/responsibility_relay.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/responsibility_relay.png'
        },
        {
            localPath: 'assets/img/challengeImages/support_web.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/support_web.png'
        },
        {
            localPath: 'assets/img/challengeImages/tally_tracker.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/tally_tracker.png'
        },
        {
            localPath: 'assets/img/challengeImages/task_team_up.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/task_team_up.png'
        },
        {
            localPath: 'assets/img/challengeImages/visionary_voyage.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/new_challenge_bank_images2/Empida+Illustrations_new/Empida+Illustrations/visionary_voyage.png'
        },










    ];

    rewardImagesArray = [
        {
            localPath: 'assets/img/reward-images/img1.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/001_gift.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img2.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/002_drinks.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img3.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/003_clothes_360.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img4.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/004_vacation.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img5.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/005_transport.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img6.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/006_food.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img7.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/007_trophy.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img8.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/008_deliveries.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img9.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/009_hand_clap.jpg'
        },
        {
            localPath: 'assets/img/reward-images/img10.jpg',
            url: 'https://empida.s3-us-west-2.amazonaws.com/challenge-rewards/010_Charity_Donation.jpg'
        },
    ];

    wellnessImageArray = [
        {
            localPath: 'assets/img/wellness-images/breathing_exercises_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/breathing_exercises_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/bullying_in_the_workplace_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/bullying_in_the_workplace_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/communication_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/communication_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/conflict_resolution_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/conflict_resolution_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/delayed_gratification_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/delayed_gratification_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/emotional_Intelligence_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/emotional_Intelligence_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/employee_well-being_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/employee_well-being_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/find_your_strengths_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/find_your_strengths_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/gratitude_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/gratitude_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/imposter_syndrome_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/imposter_syndrome_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/laughter_exercises_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/laughter_exercises_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/leadership_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/leadership_illustration.png'
        },

        {
            localPath: 'assets/img/wellness-images/meditation_goals_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/meditation_goals_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/mindfulness_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/mindfulness_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/optimism_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/optimism_illustration.png'
        },{
            localPath: 'assets/img/wellness-images/psycological_safety_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/psycological_safety_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/relationships_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/relationships_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/self_efficacy_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/self_efficacy_illustration.png'
        },{
            localPath: 'assets/img/wellness-images/self_soothing_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/self_soothing_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/setting_goals_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/setting_goals_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/sleep_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/sleep_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/social_skills_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/social_skills_illustration.png'
        },{
            localPath: 'assets/img/wellness-images/strengths_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/strengths_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/stress_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/stress_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/time_management_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/time_management_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/use_your_strengths_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/use_your_strengths_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/work_environment_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/work_environment_illustration.png'
        },
        {
            localPath: 'assets/img/wellness-images/gratitude_journaling_illustration.png',
            url: 'https://empida.s3.us-west-2.amazonaws.com/wellnessImage/gratitude_journaling_illustration.png'
        },

    ]


    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
        private dialogRef: MatDialogRef<GalleryModalComponent>,
        private fb: FormBuilder,
        private auth: AuthServiceLocal,
        private snackbar: MatSnackBar,
        private cd: ChangeDetectorRef,
        public uploadImage:GalleryModalService
       ) {
        this.mode = this.defaults.mode;
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    /**
     * function used for select image
     * @param image = imageUrl
     * @param type = 'rewardPhoto' or 'photo'
     * @param item = html element
     */
    selectImage(image, type, item) {
        if (type === 'rewardPhoto') {
            this.rewardPhotoUrl = image;
        } else if (type === 'photo') {
            this.photoUrl = image;
        }else if (type === 'wellnessImage') {
            // console.log("image ",image)
            this.wellnessImageUrl = image;
        }
        const elements = document.querySelectorAll('.image-box-wrapper .image');
        elements.forEach(ele => {
            ele.classList.remove('selected');
        });
        item.classList.add('selected');
    }


    /**
     * Create Challenge
     * */
    submitImage() {
        this.dialogRef.close({
            photoUrl: this.photoUrl,
            rewardPhoto: this.rewardPhotoUrl,
            wellnessImageUrl:this.wellnessImageUrl
        });
    }
    public dropped(files: NgxFileDropEntry[], dropperFor: 'thumb' | 'banner',type) {
        // console.log(files)
        // this.files = files;
        this.dialogRef.close(GalleryModalComponent);
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    this.uploadImage.openCropperForProfile(file,type);
    
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }


    
}


