import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { LaunchChallengeSuccessfullyDialogComponent } from '../launch-challenge-successfully-dialog/launch-challenge-successfully-dialog.component';
import { AuthServiceLocal } from '../services/auth.service';
import { SlackMembersDialogComponent } from '../slack-members-dialog/slack-members-dialog.component';
import * as XLSX from "xlsx";
import icClose from "@iconify/icons-ic/twotone-close";
import icRightArrow from "@iconify/icons-ic/keyboard-arrow-right";
import icLeftArrow from "@iconify/icons-ic/keyboard-arrow-left";
import clock from "@iconify/icons-ic/round-access-time-filled";
import { emailDomains } from '../utils/systemEnums';

type AOA = any[][];
@Component({
  selector: 'vex-launch-survey-challenge-dailog',
  templateUrl: './launch-survey-challenge-dailog.component.html',
  styleUrls: ['./launch-survey-challenge-dailog.component.scss', './../inivated-on-boarding-flow/inivated-on-boarding-flow.component.scss'],
})
export class LaunchSurveyChallengeDailogComponent implements OnInit {

  @ViewChild("stepper", { static: false }) stepper: MatStepper;


  isLinear = false;
  selectedIndex: any = 0;
  isOptional: Boolean = true;
  challengeRecommendationForm: FormGroup;
  employeeTypeForm: FormGroup;
  challengeRecommendationsIndex = 0;
  isChallengeEdit: boolean = true;
  isActionForEmployeeOrTeam: boolean = true;
  isNewChallengeOrExisting: boolean = true;
  challengeLaunchDates: FormGroup;
  currentDate = new Date();
  maxDate = new Date(1735649084000);
  recommendedDate = new Date();
  isSlackModelOpen: boolean = false;
  icRightArrow = icRightArrow;
  icLeftArrow = icLeftArrow;
  clock = clock;
  icClose = icClose;

  launchScreen: any;
  isDivVisible: boolean = false;
  weeklyActionGroup: FormGroup;
  isAnySelected: boolean = false;
  isNotificationType: any;
  slackPayload: any;
  slackIntergrationStatus: boolean = false;
  newWindow: Window;
  isSlackFlow: boolean = false;
  chipsEmails: any = [];
  isNewTeamSelected: boolean = true
  teamList: any;
  notificationType: any = 0;
  emailUpload: FormGroup;
  isChannelTypeBtnDisabled: boolean = false;
  departmentNameFormGroup: any

  defaultTime: any = new Date();
  departmentList: any


  teamNameFormGroup: FormGroup
  isVaildDomain: boolean = false;
  emaildata: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
  fileName: string = "SheetJS.xlsx";
  uploadedEmailCount: any;
  emailInput = new FormControl("", [
    Validators.required,
    Validators.pattern(
      /^(\s*[\w\.-]+(\+\d+)?@[\w\.-]+\s*,\s*)*[\w\.-]+(\+\d+)?@[\w\.-]+\s*$/
    ),
  ]);


  emailDomains = emailDomains;

 

  weeklyNotificationsPayload: {
    weeklyNotifications: any[];
    hour: any;
    minute: any;
  };


  preferredChannel = [
    { title: `We'll inform your teammates about the action` },
    { title: "Nudge them to complete" },
    { title: "Send you status reports each Friday" },
  ];

  weeklyAction = [
    {
      id: 0,
      shortTitle: "mon",
      title: "Monday",
      isSelected: false,
      isEdit: false,
      notification: "",
    },
    {
      id: 1,
      shortTitle: "tue",
      title: "Tuesday",
      isSelected: false,
      isEdit: false,
      notification: "",
    },
    {
      id: 2,
      shortTitle: "wed",
      title: "Wednesday",
      isSelected: false,
      isEdit: false,
      notification: "",
    },
    {
      id: 3,
      shortTitle: "thu",
      title: "Thursday",
      isSelected: false,
      isEdit: false,
      notification: "",
    },
    {
      id: 4,
      shortTitle: "fri",
      title: "Friday",
      isSelected: false,
      isEdit: false,
      notification: "",
    },
  ];
  selectedChallengeTemplate: any;





  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LaunchSurveyChallengeDailogComponent>,
    public cd: ChangeDetectorRef,
    private auth: AuthServiceLocal,
    // private window: Window,
    private _snackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe

  ) {

    console.log("this.data?.isFirstChallenge", this.data)

    this.data?.recommendations.forEach(obj => {
      obj['isSelected'] = 0;

    });

    this.teamNameFormGroup = this._formBuilder.group({
      teamName: [""],
      teamId: [""]
    })


    this.weeklyActionGroup = this._formBuilder.group({
      time: ["", Validators.required],
    });

    this.emailUpload = this._formBuilder.group({
      file: ["", Validators.required],
    });

    this.challengeLaunchDates = this._formBuilder.group({
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });

    this.departmentNameFormGroup = this._formBuilder.group({
      departmentName: [""],
      departmentId: [""]
    })

    this.challengeRecommendationsIndex = this.data?.templateId


    console.log("hello", this.data)
  }

  ngOnInit(): void {


    this.defaultTime.setHours(9, 45); // Set the time to 09:45
    this.getDepartmentList()


    this.weeklyActionGroup.get('time').valueChanges.subscribe((res) => {
      console.log("hello in  time in value change ", res)
    })

    this.challengeLaunchDates.get("startDate").valueChanges.subscribe((res) => {
      console.log("hello in startdate response ===>", res);

      const endDate = this.addDaysToTimestamp(res, 28); // 4 week 4*7 = 28
      console.log("this is the endDate", new Date(endDate));
      this.challengeLaunchDates.get("endDate").setValue(new Date(endDate));
    });
  }

  // add 4 weeks dayes in today's date. if today date is selected for launch challenge.
  addDaysToTimestamp(timestamp, days) {
    const milliseconds = days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
    const newTimestamp = new Date(timestamp);
    newTimestamp.setTime(newTimestamp.getTime() + milliseconds);
    return newTimestamp;
  }


  getRecommendedDate() {
    if (this.isNewTeamSelected) {
      this.auth.getRecommendStartDate().subscribe((response: any) => {
        if (response.status == 200) {
          this.recommendedDate = response.data.recommendedDate;
        } else {
          this._snackBar.open(response.message, "Okay", { duration: 2000 });
        }
      });
    } else {
      let payload = {
        teamIds: this.teamNameFormGroup.value.teamId
      }
      this.auth.getStartDateForTeamSelection(payload).subscribe((response: any) => {
        if (response.status == 200) {
          this.recommendedDate = response.data.startDate;
          this.currentDate = new Date(response.data.startDate);

        } else {
          this._snackBar.open(response.message, "Okay", { duration: 2000 });
        }
      });
    }
  }

  getTeamsData(departmentId: any) {

    this.auth.getManagerCreatedTeams(departmentId).subscribe((response: any) => {
      if (response.status === 200) {

        this.teamList = response.data.teams

        this.cd.detectChanges()
      } else {
        this.teamList = []
        // this._snackBar.open(response.message);
      }
    });
  }


  getDepartmentList() {
    this.auth.getDepartmentList().subscribe((response: any) => {
      if (response.status === 200) {

        this.departmentList = response.data

        this.cd.detectChanges()
      } else {
        this.departmentList = []
        // this._snackBar.open(response.message);
      }
    });
  }




  selectionChange(event: any) {
    this.selectedIndex = event.selectedIndex;
  }

  onSave(eventData: any) {
    if (this.data && this.data.recommendations && this.challengeRecommendationsIndex !== undefined) {
      this.data.recommendations[this.challengeRecommendationsIndex].description = eventData?.target?.innerHTML;
    }
  }

  next() {
    if (this.data?.recommendations.length != this.challengeRecommendationsIndex + 1) {
      this.challengeRecommendationsIndex = this.challengeRecommendationsIndex + 1;

      if (!this.isChallengeEdit) {
        this.isChallengeEdit = !this.isChallengeEdit;
      }
    }
  }

  pre() {
    if (this.challengeRecommendationsIndex > 0) {
      this.challengeRecommendationsIndex = this.challengeRecommendationsIndex - 1;

      if (!this.isChallengeEdit) {
        this.isChallengeEdit = !this.isChallengeEdit;
      }
    }
  }


  selectChallenge() {
    console.log("recommendation", this.data?.recommendations[this.challengeRecommendationsIndex]?.id);

    this.data?.recommendations.forEach((element) => {
      if (element.id == this.data?.recommendations[this.challengeRecommendationsIndex]?.id) {
        element.isSelected == 0
          ? (element.isSelected = 1)
          : (element.isSelected = 0);
      } else {
        element.isSelected = 0;
      }
      this.cd.detectChanges();

    });
    this.cd.detectChanges();
  }

  saveChallenge(challenge) {
    this.selectedChallengeTemplate = challenge

    this.weeklyAction.forEach((element) => {
      element.notification =
        challenge.notificationMessage;
    });
    console.log("hello selected challenge", challenge)
    this.stepper.next()
  }

  switchTeamType(type) {
    if (type == 'existingTeam') {
      this.isNewTeamSelected = false;

      this.teamNameFormGroup.reset()
    }
    else {
      this.isNewTeamSelected = true
      this.teamNameFormGroup.reset()

    }
  }


  switchDepartment(type) {
    if (type == "existing") {
      this.isNewChallengeOrExisting = false
      this.departmentNameFormGroup.reset()
      this.cd.detectChanges()
    } else {
      this.isNewChallengeOrExisting = true
      this.departmentNameFormGroup.reset()
      this.cd.detectChanges()

    }
  }

  teamOnChange(value) {
    console.log("value ===> ", value)
    // this.selectedTeamId = value;
    this.teamNameFormGroup.get('teamId').setValue(value)
    // this.teamNameFormGroup.value.teamName,
  }

  onDepartmentChange(value) {

    this.getTeamsData(value)
  }

  onDepartmentSelect(value) {
    this.departmentNameFormGroup.get('departmentName').setValue(value.title)
  }


  switchEmployeeType(type) {
    console.log("switch employee")
    if (type == "team") {
      this.isActionForEmployeeOrTeam = false;

      // this.employeeTypeForm.reset();

      this.cd.detectChanges();

    } else {
      this.isActionForEmployeeOrTeam = true;


      this.cd.detectChanges();
    }
  }

  saveEmployeeType() {

  }

  saveTeamData() {
    console.log("hello in save ", this.teamNameFormGroup)

    this.stepper.next();


  }

  getNotification(data) {
    console.log("set notification type ", data)
    this.notificationType = data.notificationType;
  }

  saveAllTeamData(value) {
    let params = {
      teamName: value.teamName,
    };




    // console.log("current step after team name save ", this.selectedIndex)
    this.stepper.next();
  }


  customize(data) {
    this.weeklyAction.forEach((element) => {
      if (element.id == data.id) {
        return element.isEdit
          ? (element.isEdit = false)
          : (element.isEdit = true);
      }
    });

    this.cd.detectChanges();
  }

  isSubmitActionBtnDisabled(): boolean {
    if (!this.weeklyActionGroup.value.time) {
      return true;
    }

    if (this.weeklyAction.some((x) => x.isSelected)) {
      return false;
    }

    return true;
  }


  showDiv() {
    this.isDivVisible = true;
  }

  hideDiv() {
    this.isDivVisible = false;
  }

  isPreviewBtnDisabled() {
    return this.weeklyAction.some((x) => x.isSelected);
  }

  sendWeeklyNotification() {
    let weeklyNotifications = [];

    const tempWeeklyNotification = this.weeklyAction.filter(
      (item) => item.isSelected
    );

    weeklyNotifications = tempWeeklyNotification.map((obj) => {
      return {
        day: obj.id,
        notification: obj.notification,
      };
    });


    this.weeklyNotificationsPayload = {
      weeklyNotifications: weeklyNotifications,
      hour: this.getHourMins(this.weeklyActionGroup.value.time).hours,
      minute: this.getHourMins(this.weeklyActionGroup.value.time).minutes,
    };

    this.getRecommendedDate()
    this.stepper.next();
  }


  getHourMins(data) {
    // Extract hours and minutes
    var hours = data.getHours();
    var minutes = data.getMinutes();

    // Formatting the hours and minutes
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Constructing the time string
    return { hours, minutes };
  }


  sendNotification(type) {

    this.isChannelTypeBtnDisabled = true

    this.isNotificationType = type;
    this.cd.detectChanges();

    if (type == "email") {
      this.isChannelTypeBtnDisabled = false
      this.stepper.next();

    } else {
      console.log("this.slackIntergrationStatus", this.slackIntergrationStatus);
      if (this.slackIntergrationStatus) {
        this.getData(true);
      } else {
        // slack case.
        const loggedUser: any = JSON.parse(localStorage.getItem("loggedUser"));

        console.log("loggedUser ===>", loggedUser);
        const ordId = localStorage.getItem("orgId")?.length
          ? localStorage.getItem("orgId")
          : loggedUser?.organisationId;

        const state = {
          organizationId: ordId,
          isDemo: 1,
          managerId: loggedUser?.id
            ? loggedUser?.id
            : localStorage.getItem("managerId"),
        };

        console.log("state payload in popup ===>", state);
        window.open(
          `https://slack.com/oauth/v2/authorize?client_id=1179687372869.3192957739207&scope=channels:history,channels:join,channels:read,chat:write,groups:write,im:write,incoming-webhook,mpim:write,team:read,users:read,users:read.email&user_scope=channels:write,im:write,team:read,users:read,users:read.email,chat:write&state=${JSON.stringify(
            state
          )}`,
          "_blank",
          "location=yes,height=900,width=920,scrollbars=yes,status=yes"
        );
        this.isSlackFlow = true;
      }
    }
  }


  getData(res) {
    return this.auth.getIntergrationStatus().subscribe((response: any) => {
      if (response.status === 200) {
        this.isSlackModelOpen = true;

        this.cd.detectChanges();
        this.slackIntergrationStatus = response.data.isSlackIntegrated;
        console.log(
          "get Intergration status ",
          response.data.isSlackIntegrated
        );

        if (this.slackIntergrationStatus && res) {
          this.openSlackMembersListing();
        }
        // this.__snackBar.open(response.message);
      } else if (response.status === 400) {
        this.slackIntergrationStatus = response.data.isSlackIntegrated;
        // this.__snackBar.open(response.message);
      } else {
        this._snackBar.open(response.message);
      }
    });
  }

  selectDay(data) {
    console.log("hello in select day", data);

    this.weeklyAction.forEach((element) => {
      if (element.id == data.id) {
        element.isSelected == false
          ? (element.isSelected = true)
          : (element.isSelected = false);
      }
    });

    this.cd.detectChanges();

    this.isAnySelected = this.weeklyAction.some(
      (obj) => obj.isSelected === true
    );

    this.cd.detectChanges();

    if (!this.isAnySelected) {
      this.weeklyAction.forEach((obj) => {
        if (obj.isSelected === undefined || obj.isSelected === false) {
          this.isAnySelected = false;
        }
      });
      this.cd.detectChanges();
    }

    console.log("isAnySelected ==>", this.isAnySelected);
  }


  addEmail() {
    if (this.emailInput.invalid) {
      this._snackBar.open("Please enter vaild email", "Okay", {
        duration: 2000,
      });
    } else {
      if (this.emailInput.value.includes("@")) {
        var domain = localStorage.getItem("domain");
        domain = domain.replace(/"/g, "");

        console.log("domain", domain);
        if (this.emailInput.value.includes(domain)) {
          var tempEmailAddress = this.emailInput.value
            .split(",")
            .map((email: string) => email.trim());

          tempEmailAddress.forEach((element) => {
            if (element.includes(domain)) {
              this.chipsEmails.push(element);
            } else {
              this.isVaildDomain = true;
              this.emailInput.setValue(element);
              this._snackBar.open(
                element + " is not an vaild work email",
                "Okay",
                { duration: 4000 }
              );
              this.cd.detectChanges();
              return;
            }
          });

          this.emailInput.setValue("");
          this.emaildata = [];
          this.emailUpload.get("file").setValue("");
          this.isVaildDomain = false;
          this.cd.detectChanges();
        } else {
          this.isVaildDomain = true;
          this._snackBar.open("Please enter vaild work email", "Okay", {
            duration: 2000,
          });
          this.cd.detectChanges();
          return;
        }
      }
    }
  }

  onRemoveEmail(i) {
    this.chipsEmails.splice(i, 1);
  }

  onFileChange(evt: any) {
    console.log("file changed", this.emailUpload.get("file").value);
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    console.log("target", target.files);
    if (target.files.length > 1) {
      this._snackBar.open("Cannot use multiple files", "OKAY");
      this.uploadedEmailCount = "";
      this.emailUpload.get("file").setValue("");
      // throw new Error('Cannot use multiple files')
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      if (this.chipsEmails.length) {
        this.chipsEmails = [];
      }
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.emaildata = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.emaildata = this.emaildata.filter((element: any) => element != "");
      if (this.emaildata?.length) {
        let pattern: any =
          /^(?!.*@.*@.*$)(?!.*@.*--.*\..*$)(?!.*@.*-\..*$)(?!.*@.*-$)((.*)?@.+(\..{1,11})?)$/;
        function checkEmailValidation(email) {
          return pattern.test(email);
        }

        if (this.emaildata.every(checkEmailValidation)) {
          this._snackBar.open("Success", "OKAY");
          this.uploadedEmailCount = this.emaildata.length;
          console.log("this.data", this.emaildata);
          let emailArr = [];
          this.emaildata.map((element) => {
            emailArr.push(element[0].toString());
          });
          this.emailUpload.get("file").setValue(emailArr);
        } else {
          this.emaildata = [];
          this._snackBar.open("Invalid Email. Select Again", "OKAY");
          this.emailUpload.get("file").setValue("");
          this.uploadedEmailCount = "";
        }
      } else {
        this.emaildata = [];
        this._snackBar.open("No Data Found In Sheet. Select Again", "OKAY");
        this.emailUpload.get("file").setValue("");
        this.uploadedEmailCount = "";
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  saveEmails() {
    const filterEmails: any = [];
    if (this.emaildata.length) {
      this.emaildata.forEach((innerArray, index) => {
        filterEmails[index] = innerArray[0];
      });

      localStorage.setItem("emailId", filterEmails);
    } else {
      localStorage.setItem("emailId", this.chipsEmails);
    }

    if (this.chipsEmails?.length || filterEmails?.length) {
      console.log("hello in test ");
      this.launchTemplate(this.isNotificationType);
    } else {
      this.addEmail();
      this.launchTemplate(this.isNotificationType);
    }
    // this.stepper.next();
  }




  openSlackMembersListing() {
    this.auth.getSlackChannelMembersList().subscribe((res: any) => {
      if (res.status == 200) {
        console.log("res members list", res.data);

        const dailog = this._matDialog.open(SlackMembersDialogComponent, {
          width: "80%",
          height: "80vh",
          panelClass: ["inivatedManagerFlow", "slackMembersWrapper"],
          hasBackdrop: true,
          data: {
            members: res.data.members?.length ? res.data.members : [],
          },
        });

        dailog.afterClosed().subscribe((members) => {
          if (members.length) {
            console.log("isActionForEmployeeOrTeam", this.isActionForEmployeeOrTeam)
            console.log("this.data?.isFirstChallenge", this.data?.isFirstChallenge)

            this.slackPayload = {
              isNewteam: this.data?.isFirstChallenge == 0 ? 1 : this.isActionForEmployeeOrTeam == true ? 1 : this.isNewTeamSelected == true ? 1 : 0,
              templateId: this.selectedChallengeTemplate.id,
              startDate: new Date(
                this.challengeLaunchDates?.value?.startDate
              ).getTime(),
              endDate: new Date(this.challengeLaunchDates?.value?.endDate).getTime(),
              isSingleUser: this.data?.isFirstChallenge == 0 ? 1 : this.isActionForEmployeeOrTeam == true ? 1 : this.isNewTeamSelected == true ? 1 : 0,
              teamName: this.teamNameFormGroup.value.teamName,
              title: this.selectedChallengeTemplate?.title,
              description: this.selectedChallengeTemplate?.description,
              improvementArea: this.selectedChallengeTemplate.improvementArea,
              slackUserIds: [],
              notificationType: 2,
              emailIds: [],
              weeklyNotifications:
                this.weeklyNotificationsPayload.weeklyNotifications,
              hour: this.weeklyNotificationsPayload.hour,
              minute: this.weeklyNotificationsPayload.minute,
              departmentName: this.departmentNameFormGroup.value.departmentName,
              departmentId: this.departmentNameFormGroup.value.departmentId

            };

            if (this.slackPayload.isNewteam == 0 && this.slackPayload.isSingleUser == 0) {
              this.slackPayload.teamId = this.teamNameFormGroup.value.teamId;
            }



            if (this.data.surveyId) {
              this.slackPayload.surveyId = this.data.surveyId
            }


            members.forEach((item) => {
              this.slackPayload.slackUserIds.push({
                id: item.id,
                email: item.profile.email,
              });
            });
            this.stepper.next();
            console.log(
              "this is final payload in slack case",
              this.slackPayload
            );

            this.launchTemplate(this.isNotificationType);
          }
        });
      }
    });
  }

  @HostListener("window:focus", ["$event"])
  onWindowFocus(event) {
    console.log("event", event);
    if (!this.slackIntergrationStatus && this.isSlackFlow) {
      this.getData(true);
    }
  }




  launchTemplate(type) {
    console.log("value in launch template ==>", this.selectedChallengeTemplate);

    if (type == "email") {
      const filterEmails = [];
      if (this.emaildata.length) {
        this.emaildata.forEach((innerArray, index) => {
          filterEmails[index] = innerArray[0];
        });
      }


      const payload: any = {
        isNewteam: this.data?.isFirstChallenge == 0 ? 1 : this.isActionForEmployeeOrTeam == true ? 1 : this.isNewTeamSelected == true ? 1 : 0,
        templateId: this.selectedChallengeTemplate.id,
        startDate: new Date(
          this.challengeLaunchDates?.value?.startDate
        ).getTime(),
        endDate: new Date(this.challengeLaunchDates?.value?.endDate).getTime(),
        isSingleUser: this.data?.isFirstChallenge == 0 ? 1 : this.isActionForEmployeeOrTeam == true ? 1 : this.isNewTeamSelected == true ? 1 : 0,
        teamName: this.teamNameFormGroup.value.teamName,
        title: this.selectedChallengeTemplate?.title,
        description: this.selectedChallengeTemplate?.description,
        improvementArea: this.selectedChallengeTemplate.improvementArea,
        slackUserIds: [],
        notificationType: 1,
        emailIds: this.chipsEmails.length ? this.chipsEmails : filterEmails,
        weeklyNotifications:
          this.weeklyNotificationsPayload.weeklyNotifications,
        hour: this.weeklyNotificationsPayload.hour,
        minute: this.weeklyNotificationsPayload.minute,
        departmentName: this.departmentNameFormGroup.value.departmentName,
        departmentId: this.departmentNameFormGroup.value.departmentId
      };

      if (payload.isNewteam == 0 && payload.isSingleUser == 0) {
        payload.teamId = this.teamNameFormGroup.value.teamId;
      }

      if (this.data.surveyId) {
        payload.surveyId = this.data.surveyId
      }

      console.log("payload data ===>", payload);

      this.auth.launchSurveyTemplateChallenge(payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.isSlackModelOpen = false
          const congratulationDialog = this._matDialog.open(
            LaunchChallengeSuccessfullyDialogComponent,
            {
              panelClass: 'inivatedManagerFlow',
              width: "800px",
              data: this.data?.isFirstChallenge >= 1 ? `for launching your action. We’ll keep you updated.` : `for launching your first action. We’ll keep you updated.`
            }
          );
          congratulationDialog.afterClosed().subscribe(() => {
            const isUserLoggedIn: any = JSON.parse(localStorage.getItem("loggedUser"));

            if (isUserLoggedIn) {
              this.dialogRef.close(res);
            } else {

              this.launchChallenge()
            }

          });
        }
        else {
          this._snackBar.open(res.message, "OKAY", { duration: 2000 });
        }
      });
    } else {
      (this.slackPayload.startDate = new Date(
        this.challengeLaunchDates.get("startDate").value
      ).getTime()),
        (this.slackPayload.endDate = new Date(
          this.challengeLaunchDates.get("endDate").value
        ).getTime());

      console.log("slack payload ", this.slackPayload);

      this.auth.launchSurveyTemplateChallenge(this.slackPayload)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this._snackBar.open(res.message, "OKAY", { duration: 2000 });

            const congratulationDialog = this._matDialog.open(
              LaunchChallengeSuccessfullyDialogComponent,
              {
                panelClass: "inivatedManagerFlow",
                width: "800px",
                data: this.data?.isFirstChallenge >= 1 ? `for launching your action. We’ll keep you updated.` : `for launching your first action. We’ll keep you updated.`
              }
            );
            congratulationDialog.afterClosed().subscribe(() => {
              // this.managerDashboardData();
              const isUserLoggedIn: any = JSON.parse(
                localStorage.getItem("loggedUser")
              );

              if (isUserLoggedIn) {
                this.dialogRef.close(res);
              } else {
                this.launchChallenge()
              }
            });
          } else {
            this._snackBar.open(res.message, "OKAY", { duration: 2000 });
          }
        });
    }
  }


  launchChallenge() {

  }

}
