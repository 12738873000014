import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icSearch from '@iconify/icons-ic/baseline-search'
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'vex-select-senior-leader-dialog',
  templateUrl: './select-senior-leader-dialog.component.html',
  styleUrls: ['./select-senior-leader-dialog.component.scss']
})
export class SelectSeniorLeaderDialogComponent implements OnInit {

  form: FormGroup;
  icClose = icClose;
  icSearch = icSearch;
  seniorList : any;
  searchCtrl = new FormControl();
  searchText: string;

  constructor(  
    public _dialogRef : MatDialogRef<SelectSeniorLeaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public _data:any,
    public _formBuilder: FormBuilder,
    private cd : ChangeDetectorRef
  ) 
  {
    this.seniorList = this._data.list
   }

  ngOnInit(): void {
    this.cd.detectChanges();
  }

  selectLeader(value){  
  }

  selectedValue(value){

    console.log("value in selected value===> ", value)
    this.seniorList.filter((item)=>{
      if (item.id == value) {          
        if (!item?.isSenior) {
          item['isSenior'] = 1;
        } else {
          item['isSenior'] = 0;
        }
      }
      console.log("This is item" , item);
    })

  }

  checkCheckBoxvalue(value){

  }

  continue(){
    this._dialogRef.close(this.seniorList)
  }


  close() {
    this._dialogRef.close({})
  }
}
