import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PariseSearchBoxComponent } from '../parise-search-box/parise-search-box.component';

@Component({
  selector: 'vex-success-emoji',
  templateUrl: './success-emoji.component.html',
  styleUrls: ['./success-emoji.component.scss']
})
export class SuccessEmojiComponent implements OnInit {
  emojiType: any;
  emojiTitle: any;

  constructor(private dialogRef: MatDialogRef<PariseSearchBoxComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
      console.log("data ",this.data)
      this.emojiType = this.data;

      if(this.emojiType == 1){
        this.emojiTitle = "Chocolate"
      }else if(this.emojiType == 2){
        this.emojiTitle = "High-five"
      }else if(this.emojiType == 3){
        this.emojiTitle = "Celebration"
      }else if(this.emojiType == 4){
        this.emojiTitle = "Sad"
      }else{
        this.emojiTitle = "Star"
      }
     }

  ngOnInit(): void {
  }

}
