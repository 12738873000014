<div class="card">
  <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
    <h2 class="m-0 title" fxFlex="auto">Sales Overview</h2>

    <button mat-icon-button type="button">
      <mat-icon [icIcon]="icCloudDownload" class="text-secondary"></mat-icon>
    </button>

    <button mat-icon-button type="button">
      <mat-icon [icIcon]="icMoreHoriz" class="text-secondary"></mat-icon>
    </button>
  </div>

  <div class="py-6 px-6" fxLayout="row" fxLayout.lt-md="column">
    <div class="pb-6 pt-4 px-6" fxFlex="auto" fxLayout="column">
      <h2 class="display-2 font-bold m-0">{{ total }}</h2>
      <h3 class="title font-medium mt-2 mb-0">Sales this month</h3>

      <div class="mt-6" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div class="w-8 h-8 rounded text-green bg-green-light"
             fxFlex="none"
             fxLayout="row"
             fxLayoutAlign="center center">
          <ic-icon [icon]="faCaretUp" height="20px" inline="true" width="20px"></ic-icon>
        </div>

        <p class="text-secondary m-0"><span class="font-medium text-green">11% more sales</span> in comparison to
          last
          month.</p>
      </div>

      <div class="mt-4" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div class="w-8 h-8 rounded text-red bg-red-light"
             fxFlex="none"
             fxLayout="row"
             fxLayoutAlign="center center">
          <ic-icon [icon]="faCaretDown" height="20px" inline="true" width="20px"></ic-icon>
        </div>

        <p class="text-secondary m-0"><span class="font-medium text-red">-2% revenue per sale</span> in comparison
          to
          last month.</p>
      </div>

      <div class="mt-6" fxFlex="auto" fxLayout="column" fxLayoutAlign="end">
        <button color="primary" mat-raised-button type="button">
          VIEW DETAILS
        </button>
      </div>
    </div>

    <div fxFlex="50%" fxFlexOffset="48px">
      <vex-chart [options]="options" [series]="series"></vex-chart>
    </div>
  </div>
</div>
