import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';
import { AuthServiceLocal } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'vex-filter-survey-report-dialog',
  templateUrl: './filter-survey-report-dialog.component.html',
  styleUrls: ['./filter-survey-report-dialog.component.scss']
})
export class FilterSurveyReportDialogComponent implements OnInit {

  selectedMember: string;
  teamList: any[] = [];


  
  // isAllSelected = true;
  // isMaleSelected = false;
  // isFemaleSelected = false;

  selectedGender: any = '';

  icClose = icClose;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthServiceLocal,
    private _snackbar: MatSnackBar,
    public _matDialogRef: MatDialogRef<FilterSurveyReportDialogComponent>
  ) { }

  ngOnInit(): void {
    this.getTeamData()
  }


  submit() {

    console.log("this.selected member ", this.selectedMember);


    if (this.selectedMember) {

      const payload = {
        "team": this.selectedMember,
        "gender": this.selectedGender,
        "surveyId": this.data.surveyId
      }

      this._matDialogRef.close(payload)
    } else {
      this._snackbar.open("Please select atleast one team", "Okay", { duration: 3000 })
    }
  }

  // selectOnly(gender: string) {
  //   if (gender === 'All') {
  //     this.isAllSelected = true;
  //     this.isMaleSelected = false;
  //     this.isFemaleSelected = false;
  //     this.selectedGender = ''
  //   } else if (gender === 'Male') {
  //     this.isAllSelected = false;
  //     this.isMaleSelected = true;
  //     this.isFemaleSelected = false;
  //     this.selectedGender = 1;

  //   } else if (gender === 'Female') {
  //     this.isAllSelected = false;
  //     this.isMaleSelected = false;
  //     this.isFemaleSelected = true;
  //     this.selectedGender = 2;
  //   }
  // }


  getTeamData() {
    this.authService.getSurveyReportTeamList({ surveyId: this.data.surveyId }).subscribe((res: any) => {
      if (res.status == 200) {

        this.teamList = res.data

      } else {
        this._snackbar.open(res.message, "Okay", { duration: 2000 })
      }

    })

  }

}
