import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';

@Component({
  selector: 'vex-show-challenge-date',
  templateUrl: './show-challenge-date.component.html',
  styleUrls: ['./show-challenge-date.component.scss']
})
export class ShowChallengeDateComponent implements OnInit {
  icClose =icClose
  constructor(
    @Inject(MAT_DIALOG_DATA) public   data: any,
    private dialogRef: MatDialogRef<ShowChallengeDateComponent>
  ) { }

  ngOnInit(): void {

  }

}
