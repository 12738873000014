

<div *ngIf="step === 1">
    <div class="flex items-center " style="height:520px">
        <div class="flex flex-col w-full welcome_sec">
            <div class="m-auto">
                <img src="assets/img/on-board/welcome_image.png" class="m-auto " >
            </div>

            <div class="text-center ">
                <h2 class="mb-3 font-medium">
                    Welcome to Empida
                </h2>
                <span class="text-md " [innerHTML]="welcomeText">
                   
                </span>
            </div>
            <div class="m-auto mt-8">
                <button mat-raised-button (click)="this.step = 2" class="mt-1 custom-close-btn" >
                   Get started
                </button>
            </div>
        </div>
    </div>

</div>


<div *ngIf="step === 2">   
    <div class="relative flex flex-col w-full survey">
        <div class="m-auto mt-5">
            <img src="assets/img/on-board/survey.svg" class="m-auto " >
        </div>

        <div class="mt-2 text-center">
            <h3 class="mt-4 mb-3 font-medium" [innerHTML]="surveyText">
               
            </h3>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4 month">
            <div *ngFor="let item of surveyMonth">
                <div class="cursor-pointer month_selection"  [ngClass]="item?.isSelected == true ? 'selectedFocus' : ''"  (click)="onCategorySelectSurvey(item)">
                    <h3>{{item.text}}</h3>
                </div>
            </div>
            
        </div>
        <div class="flex justify-between menu-icons">
            <button (click)="this.step = 1" class="mt-1 custom-close-btn" > Back  </button>

            <button (click)="this.step = 3" [disabled]="this.surveySelectedCount.length == 0" class="mt-1 custom-next-btn" >
                Next
              </button>
        </div>
    </div>    
</div>

<div *ngIf="step === 3">  

    <div class="relative flex flex-col w-full focus_sec">
        <div class="m-auto ">
            <img src="assets/img/on-board/focus_area.svg" class="m-auto " >
        </div>

        <div class="mt-1 mb-4 text-center">
            <h3 class="mt-4 font-medium" [innerHTML]="focusText">        
            
            </h3>
            <p>Select your top three.</p>
        </div>
        <div class="grid grid-cols-3 gap-4 mt-4 focus">
            <div *ngFor="let item of focusAreaforSelection">
            <div class="cursor-pointer focus_selection" [ngClass]="item?.isSelected == true ? 'selectedFocus' : ''" (click)="focusOnCategorySelect(item)" >
                <h3 >{{item.name}}</h3>
            </div>
        </div>
        </div>
        <div class="flex justify-between mt-8">
            <button (click)="this.step = 2" class="mt-1 custom-close-btn" >
            Back
            </button>

            <button (click)="focusNext()" [disabled]="this.focusSelectedCount.length == 0" class="mt-1 mr-2 custom-close-btn" >
                Next
            </button>

            
        </div>
    </div>
</div>

<div *ngIf="step === 4 ">
    <div class="relative flex flex-col w-full personalize">
        <div class="m-auto mt-4">
            <img src="assets/img/on-board/focus_area.svg" class="m-auto " >
        </div>

        <div class="mt-2 text-center">
            <h3 class="mt-4 font-medium" [innerHTML]="personalizeText"> </h3>
            <p class="personalizeText">Choose which one you would like to start with.</p>
        </div>
        <div class="flex justify-center gap-4 mt-8 focus">
            <div  *ngFor="let item of this.selectedForPersonalize">
                <div class="mt-5 cursor-pointer focus_selection" [ngClass]="item?.isSelected == true ? 'selectedFocus' : ''" (click)="personalizeOnCategorySelect(item)" >
                    <img src="">
                    <h3  >{{item.name}}</h3>
                </div>
            
            </div>
        </div>

        <div class="m-auto mt-8">
            <button mat-raised-button [disabled]="this.personalizeSelectedCount.length ==0" (click)="completeOnboard()" class="mt-4 custom-actions-btn" >
             Show my actions
            </button>
        </div>
        <div class="mt-8 ">
            <button (click)="personalizeBack()" class="mt-6 custom-back-btn" >
              Back
            </button>
        </div>
    </div>
</div>







