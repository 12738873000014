
<div *ngIf="step === 1 ">
    <div class="relative flex flex-col w-full personalize">
        <div class="m-auto mt-4">
            <img src="assets/img/on-board/focus_area.svg" class="m-auto " >
        </div>

        <div class="mt-2 text-center">
            <h3 class="mt-4 font-medium " [innerHTML]="personalizeText"> </h3>
            <p class="personalizeText">Choose which one you would like to start with.</p>
        </div>
        <div class="flex justify-center gap-4 mt-8 focus">
            <div  *ngFor="let item of this.selectedForPersonalize">
                <div class="mt-5 cursor-pointer focus_selection" [ngClass]="item?.isSelected == true ? 'selectedFocus' : ''" (click)="personalizeOnCategorySelect(item)" >
                    <img src="">
                    <h3  >{{item.name}}</h3>
                </div>
            
            </div>
        </div>

        <div class="m-auto mt-8">
            <button mat-raised-button [disabled]="this.personalizeSelectedCount?.length == 0" (click)="completeOnboard()" class="mt-4 custom-actions-btn" >
             Show my actions
            </button>
        </div>
        <div class="flex justify-between mt-8">
            <button (click)="back()" class="mt-6 custom-back-btn" >
                See all focus areas
            </button>

            <button (click)="cancel(this.selectedForPersonalize)" class="mt-6 custom-back-btn" >
                Cancel
            </button>
        </div>
    </div>
</div>



<div *ngIf="step === 2">  

    <div class="relative flex flex-col w-full focus_sec">
        <div class="m-auto ">
            <img src="assets/img/on-board/focus_area.svg" class="m-auto " >
        </div>

        <div class="mt-1 mb-4 text-center">
            <h3 class="mt-4 font-medium" [innerHTML]="focusText">        
            
            </h3>
            <p>Select your top three.</p>
        </div>
        <div class="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-3 focus">
            <div *ngFor="let item of  this.focusAreaforSelection">
            <div class="cursor-pointer focus_selection" [ngClass]="item?.isSelected == true ? 'selectedFocus' : ''" (click)="focusOnCategorySelect(item)" >
                <h3 >{{item.name}}</h3>
            </div>
        </div>
        </div>
        <div class="flex justify-between mt-8">
        
            <button (click)="this.dialogRef.close()" class="mt-1 mr-2 custom-close-btn" >
                Cancel
            </button>
            <button (click)="focusNext()" [disabled]="this.focusSelectedCount?.length == 0" class="mt-1 mr-2 custom-close-btn" >
                Next
            </button>

            
        </div>
    </div>
</div>