import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { wellnessCategory } from 'src/app/pages/wellness-categories/interfaces/wellness-category.model';
import { AuthServiceLocal } from '../services/auth.service';
import icClose from '@iconify/icons-ic/twotone-close';
import icPerson from '@iconify/icons-ic/twotone-person';
import icAdd from '@iconify/icons-ic/twotone-add';
import { languageList } from '../utils/systemEnums';
import { GalleryModalComponent } from '../gallery-modal/gallery-modal.component';
import { GalleryModalService } from '../gallery-modal/gallery-modal.service';

@Component({
  selector: 'vex-wellness-category-create-update',
  templateUrl: './wellness-category-create-update.component.html',
  styleUrls: ['./wellness-category-create-update.component.scss']
})
export class WellnessCategoryCreateUpdateComponent implements OnInit {

  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  icClose = icClose;
  icPerson = icPerson;
  icAdd = icAdd;
  TodayDate = new Date();
  MaxEndDate;
  logo = 'noImage';
  logoUrl;
  selectedOrganisationIds = [];
  deletedOrganisationIds = [];
  organisationList = [];
  languageList = languageList
  headCategory: any;
  isReuse: boolean = false;
  allWellNess: any;
  wellnessArray = [];
  TodayDateNew = new Date();
  tempObj: { name: string; value: unknown; }[];
  wellnessHeadCategoryList: { name: string; value: unknown; }[];


  /**
  * function is used to get dates for filter  
  * */
  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 6;
  }

  /**
  *  function is used to get start dates for filter  
  * */

  weekendStartDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<WellnessCategoryCreateUpdateComponent>,
    private fb: FormBuilder,
    private auth: AuthServiceLocal,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private uploadImage: GalleryModalService) {
    this.getAdminHeadCategory()
  }

  ngOnInit() {
    this.organisationList = this.defaults.organisationList;
    if (this.defaults.wellnessCategory) {
      this.mode = 'update';
      this.headCategory = this.defaults.wellnessCategory.headCategory
      this.logoUrl = this.defaults.wellnessCategory.logo;
      // console.log("image",this.logoUrl);
      this.TodayDate = new Date(this.defaults.wellnessCategory.startDate);
      this.MaxEndDate = new Date(this.defaults.wellnessCategory.startDate);
      // this.selectedOrganisationIds = this.defaults.wellnessCategory.organisationId;

    } else {
      this.defaults.wellnessCategory = {} as wellnessCategory;
    }

    this.form = this.fb.group({
      headCategory: [this.defaults.wellnessCategory.headCategory || ''],
      name: [this.defaults.wellnessCategory.name || ''],
      name_nb: [this.defaults.wellnessCategory.name_nb || ''],
      // organisationId: [this.defaults.wellnessCategory.organisationId || ''],

      startDate: [new Date(this.defaults.wellnessCategory.startDate) || ''],
      endDate: [new Date(this.defaults.wellnessCategory.endDate) || ''],
      logo: [this.defaults.wellnessCategory.logo],
      isWellNessSelected: '',
      language: 'YES',
      wellness: this.fb.array([]),
    });

    if (this.defaults.type == 'reuse') {
      this.isReuse = true;
      console.log(this.defaults.type);
      this.form.disable();
      this.TodayDate = new Date()
      //  this.form.controls['organisationId'].enable();
      this.form.controls['startDate'].enable();
      this.form.controls['endDate'].enable();
      this.form.controls['isWellNessSelected'].enable();
      this.form.get('startDate').setValue('')
      this.form.get('endDate').setValue('')
      this.getWellness(this.defaults.wellnessCategory.id);
    }
  }

  ngAfterViewInit() {

    this.uploadImage.uploadedImage.subscribe((data) => {
      console.log("this.uploadImage.", data);
      if (data.length > 0) {
        console.log("hello")
        this.logoUrl = data
        this.form.get('logo').setValue(this.logoUrl);
        this.cd.detectChanges();
      }
    })
  }

  checkValue(event) {
    // console.log(this.form.value.organisationId);
  }

  /**
   * *  Get start date value
   * */
  startDateChangeVal($event) {
    this.MaxEndDate = $event.value;

  }

  /**
   *  check form validation
   * */
  save() {
    if (this.form.invalid) {
      return false;
    } else {
      if (this.mode === 'create') {
        this.createCustomer();
      } else if (this.mode === 'update') {
        if (this.isReuse) {
          this.createCustomerNew();
        } else {
          this.updateCustomer();
        }

      }
    }

  }

  /**
   * Get cover image
   * */
  readUrl($event) {
    this.logo = $event.target.files[0];
    this.preview($event.target.files);
  }

  /**
   * For image preview
   * */
  preview(files) {
    if (files.length === 0)
      return;
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.snackbar.open("Only images are supported.");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.logoUrl = reader.result
    }
  }


  /**
 * function is used to reuse wellness category
 * */

  createCustomerNew() {
    this.form.enable();
    if (this.form.value.isWellNessSelected) {
      this.form.value.wellness.forEach((element) => {
        var startDate = new Date(element.startDate1);
        var month: any = startDate.getMonth();
        month = month < 10 ? '0' + month : month;
        var NewStartDate = Date.UTC(startDate.getFullYear(), month, startDate.getDate())
        this.wellnessArray.push({ 'wellnessId': element.wellnessIdVal, 'startDate': NewStartDate, 'endDate': NewStartDate })
      })
    }


    var NewDates = this.auth.getUtcTime(this.form.value.startDate, this.form.value.endDate)
    const postData = {
      wellnessCategoryId: this.defaults.wellnessCategory.id,
      // organisationId: this.form.value.organisationId,
      startDate: NewDates.startDate,
      endDate: NewDates.endDate,
      wellness: this.wellnessArray
    };


    this.auth.ReuseWellnessCategory(postData).subscribe((response: any) => {
      if (response.status == 200) {
        this.snackbar.open(response.message);
        this.dialogRef.close(postData);
      } else {
        this.snackbar.open(response.message);
      }
    });
  }


  /**
   *  Add Wellness Category
   * */
  createCustomer() {
    this.form.disable();
    this.form.markAsPending();
    // const FromData = new FormData();
    // FromData.append('image', this.logo);
    // FromData.append('type', 'wellness');
    var NewDates = this.auth.getUtcTime(this.form.value.startDate, this.form.value.endDate)
    // this.auth.UploadImage(FromData).subscribe((response: any) => {
    //   if (response.status == 200) {
    const postData = {
      headCategory: this.form.value.headCategory,
      logo: this.logoUrl,
      name: this.form.value.name,
      name_nb: this.form.value.name_nb,
      // organisationId: this.form.value.organisationId,
      startDate: NewDates.startDate,
      endDate: NewDates.endDate
    };
    this.auth.addWellnessCategories(postData).subscribe((response: any) => {
      this.form.enable();
      if (response.status == 200) {
        this.snackbar.open(response.message);
        this.dialogRef.close(postData);
      } else {
        this.snackbar.open(response.message);
      }
    });

    // } else {
    //   this.form.enable();
    //   this.snackbar.open(response.message);
  }
  // });
  // }



  /**
   *  Update Wellness Category
   * */
  updateCustomer() {
    if (this.form.get('language').value == 'YES') {
      this.form.get('name_nb').setValue('')
    }
    var NewDates = this.auth.getUtcTime(this.form.value.startDate, this.form.value.endDate)
    const postData = this.form.value;
    postData.headCategory = this.form.value.headCategory
    postData.wellnessCategoryId = this.defaults.wellnessCategory.id;
    // postData.organisationId = this.defaults.wellnessCategory.organisationId;
    // postData.logo = '';
    postData.startDate = NewDates.startDate;
    postData.endDate = NewDates.endDate;
    if (this.logo == 'noImage') {
      postData.logo = this.logoUrl,
        this.updateData(postData);
    } else {
      // const FromData = new FormData();
      // FromData.append('image', this.logo);
      // FromData.append('type', 'wellness');
      // this.auth.UploadImage(FromData).subscribe((response: any) => {
      //   if (response.status == 200) {
      //     postData.logo = response.data.imagePath;
      postData.logo = this.logoUrl
      this.updateData(postData);
      // } else {
      //   this.snackbar.open(response.message);
      // }
      // });
    }
  }

  /**
   * Common update wellness function for Update Wellness Data
   * */
  updateData(wellness) {
    this.auth.EditWellnessCategory(wellness).subscribe((response: any) => {
      if (response.status == 200) {
        this.snackbar.open(response.message);
        this.dialogRef.close(wellness);
        this.logoUrl = '';
        this.uploadImage.uploadedImage.next('')
        this.form.get('logo').setValue('');
      } else {
        this.snackbar.open(response.message);
      }
    });
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  get addWellnessArray() {
    return this.form.get('wellness') as FormArray;
  }


  /****/
  getWellness(wellCatId) {
    let categoryId = {
      wellnessCategoryId: wellCatId,
    };
    // return  this.auth.adminGetWellness(categoryId).subscribe((response:any) => {
    //     if(response.status==200){
    //         this.allWellNess = response.data.wellness;
    //             if(this.allWellNess){
    //                 this.allWellNess.forEach(element => {
    //                     const option = this.fb.group({
    //                         wellnessId: '',
    //                         startDate1: '',
    //                         wellnessIdVal:element.id,

    //                     });
    //                     this.addWellnessArray.push(option);
    //                 });
    //             }


    //      } else{
    //         this.snackbar.open(response.message);
    //     }
    // });
  }

  test(i) {
    console.log(this.addWellnessArray.at(i));
  }

  onWellNessChange(event, value) {
    console.log(event)
  }

  onSelectHeadCategory(val) {
    console.log('onSelectHeadCategory', val)
    this.headCategory = val.value;
  }


  /**
   * function is used to get wellness head category 
   * */
  getAdminHeadCategory() {
    return this.auth.WellnessHeadCategory().subscribe((response: any) => {
      if (response.status == 200) {
        // this.wellnessHeadCategoryList
        let temArr = [];
        temArr.push(response.data)
        var obj = (response.data)
        this.tempObj = Object.entries(obj).map((e) => ({ "name": e[0], "value": e[1] }));
        this.tempObj.forEach((element: any) => { element.name = element.name.replace('_', ' '); });
        console.log("wellness head category", this.tempObj)
        this.wellnessHeadCategoryList = this.tempObj
      } else {
        this.snackbar.open(response.message);
      }
    });
  }



  openGalleryModal(mod) {

    console.log("in", mod)
    this.dialog.open(GalleryModalComponent, {

      data: {
        mode: mod
      }
    }).afterClosed().subscribe((result: any) => {
      console.log("result data for wellness image ", result)
      if (result.wellnessImageUrl) {
        this.logoUrl = result.wellnessImageUrl;

        // this.form.controls.photo.setValue(result.photoUrl);
        this.cd.markForCheck();
      }
      // this.cd.markForCheck();
    });
  }

  dailogClose() {
    this.dialogRef.close(
      this.uploadImage.uploadedImage.next('')

    );
    this.cd.detectChanges();
  }
}

