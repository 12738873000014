import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-launch-challenge-successfully-dialog',
  templateUrl: './launch-challenge-successfully-dialog.component.html',
  styleUrls: ['./launch-challenge-successfully-dialog.component.scss']
})
export class LaunchChallengeSuccessfullyDialogComponent implements OnInit {

  color = '#fff'
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<LaunchChallengeSuccessfullyDialogComponent>,
  ) { 

    console.log("hello in launch screen===>", this.data)
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close();
    }, 4000);
  }

}
