import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardInfoComponent } from '../dashboard-info/dashboard-info.component';
import { AuthServiceLocal } from '../services/auth.service';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import ApexCharts from 'apexcharts';
import { yearFilter } from '../utils/systemEnums';

@Component({
  selector: 'vex-physical-well-being',
  templateUrl: './physical-well-being.component.html',
  styleUrls: ['./physical-well-being.component.scss']
})
export class PhysicalWellBeingComponent implements OnInit {


  icFilterList = icFilterList
  physicalWellBeingch: any;
  donationGoalCh: boolean;
  totalCmpanyActivity: any;
  companyGraphData;
  getAllCount = {
    dailyTargetPoints: null,
    subscriptionPurchased: null,
    totalTeam: null,
    totalUser: null,
    totalChallenges: null,
    lookingForColleague: null,
    lookingForColleagueMaybe: null,
    lookingForColleagueNoAction: null,
  };
  d = new Date();
  currentYear = this.d.getFullYear()
  isSelectedForPhysical = this.currentYear;

  yearFilter = yearFilter

  companyActivityChart;
  companyActivityOptions = {
    series: [{
      name: 'Company Physical Well-Being',
      data: []
    }],
    grid: {
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 16
      }
    },
    chart: {
      type: 'bar',
      height: 300,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    // stroke: {
    //   curve: 'smooth',
    //   width: 2.5
    // },
    dataLabels: {
      enabled: false
    },
    colors: ['#008ffb', '#ff9800'],
    labels: [],
    xaxis: {
      type: 'category',
      labels: {
        show: true,
        style: {
          cssClass: 'text-secondary fill-current caption font-medium',
          fontFamily: 'inherit'
        }
      },
      categories: [],
      tickPlacement: 'on'
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          const num = value.toString();
          return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
      }
    },
    legend: {
      show: true,
      itemMargin: {
        horizontal: 1,
        vertical: 1
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }
  yeardDashboardFilter: any;
  yearFilterForDashboard = {
    'year': this.currentYear
  }
  yearData: any;

  constructor(
    private dialog: MatDialog,
    private auth: AuthServiceLocal,
    private snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {
    this.getDashboardData(this.yearFilterForDashboard.year);
    this.getYearFilterData()

  }


  openModal(val, text) {
    console.log("hello were", text)
    this.dialog.open(DashboardInfoComponent, {
      height: "150px",
      panelClass: 'dashboard-dailog',
      data: {
        text: val,
        title: text
      }
    })
  }


  changeActFilter(event, type, month?) {
    this.yeardDashboardFilter = event;
    if (type == 'physical') {
      this.companyActivityChart.destroy();
      this.companyActivityChart = ''
      this.companyActivityOptions.series[0].data = []
      this.getDashboardData(this.yeardDashboardFilter)
      // this.setGraphData();
    }


  }




  openFilter(type) {
    if (type == 'physical') {
      if (this.physicalWellBeingch) {
        this.physicalWellBeingch = false;
      } else {
        this.physicalWellBeingch = true;
      }
    }

  }


  /*****
 *  Get all dashboard Data
 *****/
  getDashboardData(value) {
    const year = new Date().getFullYear();
    console.log(year);
    this.auth.getOrganisationDashboard(value).subscribe((response: any) => {
      if (response.status === 200) {
        this.getAllCount = response.data[0][0];
        this.companyGraphData = response.data[1];
        // this.donationGraphData = response.data[2];

        this.setGraphData();
      }
    });
  }



  /*****
  *  SET PHYSICAL WELL-BEING GRAPH
  *****/
  setGraphData() {
    this.companyGraphData.forEach(item => {
      this.companyActivityOptions.series[0].data.push(item.points);
      this.companyActivityOptions.xaxis.categories.push(item.monthName);
    });
    this.totalCmpanyActivity = this.companyActivityOptions.series[0].data.reduce((a, b) => a + b, 0)
    this.totalCmpanyActivity = this.totalCmpanyActivity.toString();
    this.totalCmpanyActivity = this.totalCmpanyActivity.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    this.companyActivityChart = new ApexCharts(document.querySelector('#activityChart'), this.companyActivityOptions);
    this.companyActivityChart.render();

  }
  getYearFilterData() {
    const payload = {
      isYear: 1,
      isMonth: 0,
    }

    this.auth.getFilteredData(payload).subscribe((response: any) => {
      if (response.status === 200) {
        console.log("year data ", response.data)
        this.yearData = response.data.years

      }
    });
  }


}
