import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-replace-challenge-dailog',
  templateUrl: './replace-challenge-dailog.component.html',
  styleUrls: ['./replace-challenge-dailog.component.scss']
})
export class ReplaceChallengeDailogComponent implements OnInit {
  
  replaceButtonText = 'Replace';
 
  constructor( 
     @Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<ReplaceChallengeDailogComponent>) {
  
}
ngOnInit() {
}

onReplaceClick(): void {
  this.dialogRef.close({
    isReplace: true
  });
}
onAddClick(): void {
  this.dialogRef.close({
    isAdd: true
  });
}
}