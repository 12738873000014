import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-confirmation-replace-challenge-dialog',
  templateUrl: './confirmation-replace-challenge-dialog.component.html',
  styleUrls: ['./confirmation-replace-challenge-dialog.component.scss']
})
export class ConfirmationReplaceChallengeDialogComponent implements OnInit {

  message = 'Are you sure?';
  replaceButtonText = 'Replace';
  addButtonText = 'Add';
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationReplaceChallengeDialogComponent>) {
    if (data) {
      this.message = data?.message || this.message;
      this.replaceButtonText = data?.buttonText?.replaceButtonText || this.replaceButtonText;
      this.addButtonText = data?.buttonText?.addButtonText || this.addButtonText;
    }
  }
  ngOnInit() {
  }

  onReplaceClick(): void {
    this.dialogRef.close({
      isReplace: true
    });
  }
  onAddClick(): void {
    this.dialogRef.close({
      isAdd: true
    });
  }
}