import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any, searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;

console.log("in filter ",items);
    searchText = searchText?.toLowerCase();
    return items.filter( item => {
        console.log("in the pipe",item);
        if(item.name){
          return item.name?.toLowerCase().includes(searchText);
        }else{
          return item.colleagueName?.toLowerCase().includes(searchText);
        }
        
      
    });
  }

}