
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';
import { feedbackQuestionTypes } from '../utils/systemEnums';

@Component({
  selector: 'vex-add-update-survey-question-dialog',
  templateUrl: './add-update-survey-question-dialog.component.html',
  styleUrls: ['./add-update-survey-question-dialog.component.scss']
})
export class AddUpdateSurveyQuestionDialogComponent implements OnInit {

  icClose = icClose;
  form: FormGroup;
  mode = '';
  questionTypes = feedbackQuestionTypes;
  // questionType = '1';

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<AddUpdateSurveyQuestionDialogComponent>,
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    // this.createForm(); 

    if (this._data?.question) {
      this.createForm(this._data?.question)
      this.mode = 'update';

    } else {
      this.createForm();
      this.mode = 'add';
    }
  }

  createForm(data?) {
    this.form = this._fb.group({
      id: [data?.id || null],
      tempId: [data?.tempId || null],
      answer: [{ value: '', disabled: true }],
      questionType: [this._data?.question?.questionType ? String(this._data?.question?.questionType) : '1'],
      question: [data?.question || '', Validators.required],
      options: this._fb.array([])
    })

    if (data?.options?.length) {
      data?.options.forEach((itemGroup, i) => {
        this.addRow(itemGroup, i)
      })
    } else {
      // add minimun 2 option default.
      const tempArr = [1, 2];
      tempArr.forEach((item, i) => this.addRow(null, i + 1));
    }
  }

  // FOR FORM ARRAY LOOP IN UI
  get options(): FormArray {
    return this.form.controls["options"] as FormArray;
  }

  public addRow(optionData?, i?): void {
    if (optionData) {
      console.log("option", optionData, i);
      (<FormArray>this.form.get("options")).push(
        this._fb.group({
          optionId: [i + 1, Validators.required],
          optionValue: [optionData?.optionValue || '', Validators.required]
        })
      );

    } else {
      console.log("addRow", i);
      (<FormArray>this.form.get("options")).push(
        this._fb.group({
          optionId: [i, Validators.required],
          optionValue: ['', Validators.required]
        })
      );
    }
  }


  onAddNewOption(j) {
    // this.options.markAllAsTouched();
    // if(this.options.valid){  
    this.addRow(null, j + 2);
    // }
  }

  onRemoveOption(j) {
    (<FormArray>this.form.get('options')).removeAt(j);
  }

  onSubmit() {
    if (this.form.get('questionType').value == 2) {
      this.options.controls.forEach((group) => {
        group.setValidators(null)
        group.updateValueAndValidity();
      })
      this.form.updateValueAndValidity()
      this.dialogRef.close(this.form.value);
    } else {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
      } else {
        this.form.markAsPending();
        this.dialogRef.close(this.form.value);
      }
    }

  }



  close() {
    this.dialogRef.close();
  }

}
