<section class="senior-leader">
    <div mat-dialog-title class="heading">
        <div class="relative">
            <button class="custom-close-btn absolute right-2" (click)="close()">
                <mat-icon [icIcon]="icClose"></mat-icon>
            </button>
        </div>
        <h2>{{_data.title}}</h2>
    </div>

    <!-- senior List Search -->

    <div>
        <div class="bg-card rounded-full border px-4" fxFlex="350px" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
            fxLayoutAlign="start center">
            <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
            <input [(ngModel)]="searchText" #query class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                placeholder="Search..." type="search">

        </div>
        <!-- <button class="ml-4" fxFlex="none" fxHide.gt-xs fxHide.xs mat-icon-button type="button">
                <mat-icon [icIcon]="icSearch"></mat-icon>
            </button> -->

    </div>

    <mat-dialog-content>
        <div class="content-box">

            <div *ngFor="let list of seniorList | filter: searchText " class="cursor-pointer  pt-3"
                (click)="selectLeader(list.id)">
    
                <mat-checkbox class="py-1.5 text-black mat-primary" labelPosition="before" 
                    (ngModelChange)="checkCheckBoxvalue(list)"
                     [(ngModel)]="list.isSenior"
                    (change)="selectedValue(list)">{{list.colleagueName}}</mat-checkbox>
            </div>

          
        </div>
    </mat-dialog-content>
    <div class="flex justify-center"> <button class="text-[16px] pr-8 pl-8 select-btn rounded h-12 max-h-14 text-white border-2 w-32" (click)="continue()" >{{this._data.buttonName}}</button> </div>
</section>