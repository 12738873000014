import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthServiceLocal } from '../services/auth.service';
import { FormControl } from '@angular/forms';
import icSearch from '@iconify/icons-ic/twotone-search';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';



@UntilDestroy()

@Component({
  selector: 'vex-slack-members-dialog',
  templateUrl: './slack-members-dialog.component.html',
  styleUrls: ['./slack-members-dialog.component.scss']
})
export class SlackMembersDialogComponent implements OnInit {

  filteredData : any;
  searchTerm = '';
  searchCtrl = new FormControl();
  icSearch = icSearch;
  ogMembers: any;


  constructor(
      @Inject(MAT_DIALOG_DATA) public _data: any,
      private _auth : AuthServiceLocal,
      private _dialogRef: MatDialogRef<SlackMembersDialogComponent>,
      private cd: ChangeDetectorRef
  ) { 

  

    
    if(this._data.members.length){
      
      this._data.members.forEach(element => {
          element['isSelected'] = 0
      })

      this.ogMembers = this._data.members      
      this.filteredData =  this.ogMembers

    }
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe(value => this.onFilterChange(value));
  }

  onAddMember(i,event){
    
    this.filteredData[i].isSelected = this.filteredData[i].isSelected == 0 ?  1 : 0;
    
    this.ogMembers.forEach(element => {
      if(element ==  this.filteredData[i]){
        element.isSelected == 0 ? 1 : 0
      }
    });
  

    this.cd.detectChanges()
  }

  isBtnDisabled(){
    // return !this.filteredData?.some(item => item?.isSelected)
  }

  onInviteMembers(){
    const selectedMembers = this.ogMembers.filter(item => item.isSelected)
    this._dialogRef.close(selectedMembers)
  }

 

  onFilterChange(value: string) {
    if (! this.ogMembers.length) {
      return;
    }
  
    value = value.trim().toLowerCase();
  
    if (value.length) {      
      this.filteredData = this.ogMembers.filter(member => member.name.toLowerCase().includes(value));
    } else {  
      this.filteredData = this.ogMembers;
    }
  
  
    this.cd.detectChanges();
  }
}

