import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { event } from 'src/app/pages/event/interfaces/event.model';
import { AuthServiceLocal } from '../services/auth.service';
import { languageList } from '../utils/systemEnums';

@Component({
  selector: 'vex-event-create-update',
  templateUrl: './event-create-update.component.html',
  styleUrls: ['./event-create-update.component.scss']
})
export class EventCreateUpdateComponent implements OnInit {

  // form: FormGroup;
  mode: 'create' | 'update' = 'create';
  // icClose = icClose;
  // icPerson = icPerson;
  // icAdd = icAdd;
  TodayDate = new Date();
  MaxEndDate;


  languageList = languageList
  TodayDateNew = new Date();
  tempObj: { name: string; value: unknown; }[];
  wellnessHeadCategoryList: { name: string; value: unknown; }[];



  form = this.fb.group({
    // headCategory: [this.defaults.wellnessCategory.headCategory || ''],
    title: [''],
    url: [ ''],
    points: [''],
    description:[''],
    startDate: [ ''],
    endDate: [''],
    // language: 'YES',
  });


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<EventCreateUpdateComponent>,
    private fb: FormBuilder,
    private auth: AuthServiceLocal,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    console.log("defaults data", this.defaults.eventData)
    if (this.defaults.eventData) {
      this.mode = 'update';

      console.log("hello")
      this.form = this.fb.group({
        // headCategory: [this.defaults.wellnessCategory.headCategory || ''],
        title: [this.defaults.eventData.title ],
        description: [this.defaults.eventData.description ],
        url: [this.defaults.eventData.url ],
        points: [this.defaults.eventData.points || ''],
        startDate: [new Date(this.defaults?.eventData.startDate) || ''],
        endDate: [new Date(this.defaults?.eventData.endDate) || ''],
        // language: 'YES',
      });

    } else {
      this.defaults.eventData = {} as event;
      console.log("hello in else ")
    }


   

  }



  /**
   * *  Get start date value
   * */
  startDateChangeVal($event) {
    this.MaxEndDate = $event.value;

  }

  /**
   *  check form validation
   * */
  save() {
    if (this.form.invalid) {
      return false;
    } else {
      if (this.mode === 'create') {
        this.createCustomer();
      } else if (this.mode === 'update') {
        this.updateCustomer();
      }
    }
  }


  /**
   *  Add Event 
   * */
  createCustomer() {
    console.log("add event")
    this.form.disable();
    this.form.markAsPending();

    var NewDates = this.auth.getUtcTime(this.form.value.startDate, this.form.value.endDate)
    const postData = {
      title: this.form.value.title,
      description: this.form.value.description,
      url:this.form.value.url,
      points:this.form.value.points,
      startDate: NewDates.startDate,
      endDate: NewDates.endDate
    };
    this.auth.addEvent(postData).subscribe((response: any) => {
      this.form.enable();
      if (response.status == 200) {
        this.snackbar.open(response.message);
        this.dialogRef.close(postData);
      } else {
        this.snackbar.open(response.message);
      }
    });


  }


  /**
   *  Update Event 
   * */
  updateCustomer() {
    
    var NewDates = this.auth.getUtcTime(this.form.value.startDate, this.form.value.endDate)
    const postData = {
      eventId: this.defaults.eventData.id,
      title: this.form.value.title,
      description: this.form.value.description,
      url:this.form.value.url,
      points:this.form.value.points,
      startDate: NewDates.startDate,
      endDate: NewDates.endDate
    };

    this.auth.updateEvent(postData).subscribe((response: any) => {
      if (response.status == 200) {
        this.snackbar.open(response.message);
        this.dialogRef.close(postData);

      } else {
        this.snackbar.open(response.message);
      }
    });

  }


  preventNegativeValue(event:any){
    console.log("event ",event)
    if (event.code  === 'NumpadDecimal'){
      event.preventDefault();
    }
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }


  dailogClose() {
    this.dialogRef.close(

    );
    this.cd.detectChanges();
  }
}


