import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink } from '../../../interfaces/navigation-item.interface';
import { dropdownAnimation } from '../../../animations/dropdown.animation';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import icKeyboardArrowRight from '@iconify/icons-ic/twotone-keyboard-arrow-right';
import {MatDialog} from '@angular/material/dialog';
import { AuthServiceLocal } from 'src/@vex/services/auth.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { LocalStoreService } from 'src/@vex/services/local-store.service';
import  icLock  from '@iconify/icons-ic/lock-open'

@UntilDestroy()
@Component({
  selector: 'vex-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemComponent implements OnInit, OnChanges {

  @Input() item: any;
  @Input() level: number;
  isOpen: boolean;
  isActive: boolean;
  icKeyboardArrowRight = icKeyboardArrowRight;
  icLock = icLock

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;
  isAdminLogin: any;
  userData: any;

  notificationCount :any=[];

  constructor(private router: Router, private dialog: MatDialog,
              private cd: ChangeDetectorRef,
              private navigationService: NavigationService,
              private layoutService: LayoutService,
              private store: LocalStoreService,
              private auth: AuthServiceLocal,) { 
                this.isAdminLogin = this.auth.isAdminLogin();
                // console.log("admin login",this.isAdminLogin)
              }

  @HostBinding('class')
  get levelClass() {
    return `item-level-${this.level}`;
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(() => this.onRouteChange());

    this.navigationService.openChange$.pipe(
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(item => this.onOpenChange(item));

    this.userData = this.store.getItem('loggedUser');

      
      this.navigationService.isDashboardNoticationCount.subscribe(res =>{

        // console.log("res in side nav",res?.length )

        if(res?.length > 0){
          this.notificationCount = res
        }else{
          const tempNotificationData =  localStorage.getItem('notification') ? JSON?.parse(localStorage.getItem('notification')) : '' 
          // console.log("hello in side nav", tempNotificationData?.length)

          if(tempNotificationData?.length){
            this.notificationCount = res
            this.cd.detectChanges()
          }else{
            this.notificationCount  =[]
          }
        }
        
        this.cd.detectChanges()
      })
      
      // console.log("res data of navigation data", this.notificationCount   )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
      this.onRouteChange();
    }
  }
  onClickOnSidenavItem(item) {
    // console.log('onClickOnSidenavItem',item)
    this.layoutService.selectedModule.next(item?.moduleID);
    this.store.setItem('selectedModuleID', item?.moduleID);
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
      this.cd.detectChanges();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter(child => this.isDropdown(child))
      .some(child => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some(child => {
      
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {       
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }

  // /**
  //  * function used for open notification modal
  //  * @param $event = event
  //  */
  // openNotificationModal($event) {
  //   if (this.item.route === '/send-notification') {
  //     $event.stopPropagation();
  //     this.dialog.open(NotificationsComponent);
  //     this.cd.markForCheck();
  //   }
  // }
}
