<div mat-dialog-title class="relative p-0 m-0">
    <!-- close btn -->
    <button mat-icon-button class="absolute close-button">
        <mat-icon mat-dialog-close="true" [icIcon]="icClose" height="22px" width="22px"></mat-icon>
    </button>
</div>
<div mat-dialog-content class="relative theme-preview-wrapper">
    <!-- header -->
    <div class="w-full mt-5 mb-5 text-center">
        <img draggable="false" src="assets/img/logo.png" class="inline-block mx-auto" alt="">
    </div>
    <!-- cover img -->
    <div class="mb-2 cover-wrapper">
        <h2 class="h-24 font-bold text-center text-white title">{{this._data?.formData?.title | titlecase}}</h2>
        <!-- img -->
        <img draggable="false" class="w-64 mx-auto pre-img" src="assets/img/theme_img.png" alt="">
    </div>
    <!-- {{this._data?.details.description}} -->
    <!-- descrtion -->
    <div class="my-8 text-center" id="description"></div>

    <!-- border -->
    <div class="my-2 blue-sprator"></div>

    <!-- challenges -->
    <div class="w-full">

        <h3 class="py-2 font-black leading-loose text-center title theme-color">{{this._data?.formData?.title | titlecase}}</h3>
        <!-- challenges loop -->
        <div class="mb-9" *ngFor="let challenge of this._data?.details.challenges; let i = index">
            <p class="mb-4 text-2xl font-thin text-center duration-label"> 
                {{challenge.challengeInfo.challengeDuration | uppercase}}</p>
            <div class="flex items-start mb-4" [ngClass]="(i % 2 != 0) ? 'flex-row-reverse' : ''">
                <!-- img -->
                <div class="w-2/6">
                    <img draggable="false" [src]="challenge?.emailInfo?.emailImageUrl" alt="">
                </div>
                <!-- title -->
                <div class="w-2/3 p-2">
                    <h4 class="mb-2 text-xl font-semibold leading-relaxed theme-color">{{challenge?.emailInfo?.emailTitle}}</h4>
                    <!-- <p class="text-xs leading-snug duration-label" [innerHTML]="challenge?.emailInfo?.emailDescription"></p> -->
                    <div class="text-xs leading-snug duration-label" #emailDescription></div>
                </div>
            </div>
        </div>
    </div>

    <!-- border -->
    <div class="my-2 blue-sprator"></div>

    <!-- footer -->
    <div class="py-8">
        <h5 class="text-base font-semibold leading-snug text-center theme-color">{{'(c) Empida, Employee Wellbeing All rights reserved'}}</h5>
    </div>
</div>