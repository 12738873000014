<div class="flex justify-between d-flex info-title ">
    <h4 mat-dialog-title class="mb-0 font-extrabold flex w-full justify-center ">{{"Feedback Responses"}}</h4>
    <div class="relative">
        <button class="custom-close-btn" (click)="close()">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

</div>
<hr class="mt-2 mb-3">

<div mat-dialog-content class="overflow-scroll info-details " style="min-height: 400px;">

    <div class="">

        <div *ngIf="!this.feebackDetailsData?.feedbackDetails">
            <div *ngFor="let i of [1,2,3,4,5,6,7,8]" class="w-full flex gap-4 mb-4">

                <div class="flex gap-4">
                    <div class="h-12 w-12 bg-gray-200 rounded-full mb-2 animate-pulse"></div>
                </div>

                <div class="w-full">

                    <div class="flex gap-4 justify-between">
                        <div class="h-6 w-3/6 bg-gray-200 rounded mb-2 animate-pulse"></div>
                        <div class="h-6 w-1/3  bg-gray-200 rounded mb-2 animate-pulse"></div>
                    </div>

                    <div class="">
                        <div class="h-5 w-full bg-gray-200 rounded mb-2 animate-pulse"></div>
                        <div class="h-5 w-full bg-gray-200 rounded mb-2 animate-pulse"></div>

                    </div>

                </div>
            </div>
        </div>

        <div *ngFor="let item of this.feebackDetailsData?.feedbackDetails">
            <div class="flex w-full pb-4">
                <div class="flex items-center justify-center h-10 mt-2 mr-4 rounded-full"
                    [ngStyle]="{background:item.colorCode, width:'40px'}">
                    <h4 class="w-10 text-center font-bold text-white uppercase">{{item.name.charAt(0) }}</h4>
                </div>

                <div class="flex flex-col w-full p-3">
                    <div class="flex flex-col justify-between sm:flex-row">

                        <h4 class="font-medium"> {{item.name }}</h4>
                        <h6 class="flex items-end text-xs text-gray">{{ item.date | date : 'MMM d, y, h:mm a' }}</h6>
                    </div>
                    <h6> {{item.openEndedFeedback}}</h6>

                </div>
            </div>
            <mat-divider></mat-divider>
        </div>


        <div *ngIf="!this.feebackDetailsData?.feedbackDetails?.length "
            class="w-full flex justify-center text-center items-center " style="height: 372px;">
            <div>
                <h4>{{' No Feedbacks found'}}</h4>
            </div>
        </div>
    </div>
</div>