import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import {filter, map, mergeMap, startWith} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import {AuthServiceLocal} from '../../@vex/services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ChangePasswordModalComponent} from '../../@vex/change-password-modal/change-password-modal.component';
import {LocalStoreService} from '../../@vex/services/local-store.service';
import { DOCUMENT } from '@angular/common';



@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {
  tutorialData;
  loggedUser;
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService, private dialog: MatDialog,
              private configService: ConfigService, private activatedRoute: ActivatedRoute,
              private breakpointObserver: BreakpointObserver, private store: LocalStoreService,
              private router: Router, private auth: AuthServiceLocal,
              @Inject(DOCUMENT) private document: Document) {

                // console.log("you are in custom layout component")

    this.checkUserLoggedIn();
    this.checkIsFirstTimeLogin();
  }

  ngOnInit() {
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }

  checkUserLoggedIn() {
    if (this.auth.isLoggedIn()) {
      const loggedUser = this.auth.getUser();
      if(loggedUser.colorCode) {
        const RgbColor =  this.configService.hexToRGB(loggedUser.colorCode);
        if (this.document) {
          this.document.documentElement.style.setProperty('--color-primary', RgbColor);
          this.document.documentElement.style.setProperty('--color-primary-contrast', '255,255,255');
        }
      }

      if(loggedUser.logo){
        this.configService.updateConfig({
          sidenav: {
            imageUrl: loggedUser.logo
          }
        });
      }
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }

  /**
   * function used to check IsFirstTimeLogin
   */
  checkIsFirstTimeLogin() {
    
    
  }
}
