import { ChangeDetectorRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';
import { CropperComponent } from '../cropper/cropper.component';
import { AuthServiceLocal } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class GalleryModalService {

  croppedProfileBase64: any;
  uploadedImage:any = new BehaviorSubject([]);

  constructor( private dialog: MatDialog,
    private auth: AuthServiceLocal,) { }

  

openCropperForProfile(file,type) {
    this.dialog.open(CropperComponent, {
        // height: '80vh',
        width: '50vw',
        data: { imageFile: file, ratio: 1 / 1,
        type:type }
    }).afterClosed().subscribe((dialogData: any) => {
        if (dialogData) {
            // this.uploadedImage.next(dialogData.croppedImageBase64)

            // console.log("cover image after crop", dialogData.croppedImageFile);
            // const FromData = new FormData();
            // FromData.append('image', dialogData.croppedImageFile);
            // FromData.append('type', 'coverImage');
            // this.auth.UploadImage(FromData).subscribe((response: any) => {
            //     if (response.status === 200) {
            //         this.uploadedImage.next(response.data.imagePath)                  
            //         console.log("hello there ", this.uploadedImage  );
            //     }
            // })
            // console.log(" this.photo", this.photo)
            // this.form.get('photo').setValue(this.photo);
            // this.cd.detectChanges();
            
        }
    });
}
}
