<form (ngSubmit)="save()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 *ngIf="mode=='update'" class="headline m-0" fxFlex="auto"> {{'Update Event'}} </h2>
        <h2 *ngIf="mode=='create'" class="headline m-0" fxFlex="auto">Add Event</h2>

        <button class="text-secondary" (click)="dailogClose()" mat-icon-button type="button">
            <!-- <mat-icon [icIcon]="icClose"></mat-icon> -->
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    
    <mat-dialog-content>
        <!-- <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto ">
                <mat-label>Add another language </mat-label>
                <mat-select formControlName="language">
                    <mat-option [value]="language.value" *ngFor="let language of languageList;">
                        {{language.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        
        </div> -->

        <div class="flex mt-3 flex-col sm:flex-row">
            <mat-form-field class="flex-auto full-width-field">
                <mat-label> Event Name</mat-label>
                <input #name cdkFocusInitial formControlName="title" matInput required>
                <mat-error *ngIf="form.get('title').hasError('required')">This field is required</mat-error>
            </mat-form-field>
        </div>

         <!-- <div class="flex  flex-col sm:flex-row">
            <mat-form-field class="flex-auto full-width-field">
                <mat-label>Title (Norway)</mat-label>
                <input #name cdkFocusInitial formControlName="title_nb" matInput required>
                <mat-error *ngIf="form.get('title_nb').hasError('required')">This field is required</mat-error>
            </mat-form-field>
        </div> -->

        <div class="flex  flex-col sm:flex-row">
            <mat-form-field class="flex-auto full-width-field">
                <mat-label>Event Link</mat-label>
                <input #name cdkFocusInitial formControlName="url" matInput required>
                <mat-error *ngIf="form.get('url').hasError('required')">This field is required</mat-error>
            </mat-form-field>
        </div>

        <div class="flex  flex-col sm:flex-row">
            <mat-form-field class="flex-auto full-width-field">
                <mat-label>Event earned points </mat-label>
                <input #name cdkFocusInitial formControlName="points" (keypress)="preventNegativeValue($event)" type="number" matInput required>
                <mat-error *ngIf="form.get('points').hasError('required')">This field is required</mat-error>
            </mat-form-field>
        </div>

       
        <div class="flex  flex-col sm:flex-row">
                <mat-form-field class="flex-auto full-width-field">
                    <mat-label>Short Description</mat-label>
                    <!-- <input matInput #message maxlength="256"> -->
                    <textarea matInput  #desc maxlength="140" rows="4"  formControlName="description" required></textarea>
                    <mat-hint align="end">{{desc.value.length}} / 140</mat-hint>
                </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>Start Date</mat-label>
                <input (dateChange)="startDateChangeVal($event)" 
                    [min]="TodayDate" matInput [matDatepicker]="startDate" formControlName="startDate"
                    (focus)="startDate.open()" (click)="startDate.open()" required>
                <mat-datepicker #startDate></mat-datepicker>
                <mat-error *ngIf="form.get('startDate').hasError('required')">This field is required</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-auto sm:ml-6">
                <mat-label>End Date</mat-label>
                <input [min]="MaxEndDate" matInput  [matDatepicker]="endDate"
                    formControlName="endDate" (click)="endDate.open()" (focus)="endDate.open()" required>
                <mat-datepicker #endDate></mat-datepicker>
                <mat-error *ngIf="form.get('endDate').hasError('required')">This field is required</mat-error>
            </mat-form-field>
        </div>   

    </mat-dialog-content>

    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-actions align="end">
        <button mat-button (click)="dailogClose()" type="button">CANCEL</button>
        <button *ngIf="isCreateMode()" color="primary" mat-button type="submit"
            [disabled]="(form.invalid || form.pristine) || form.pending">ADD EVENT</button>
        <button *ngIf="isUpdateMode()" color="primary" mat-button type="submit" [disabled]="form.invalid">
            {{'UPDATE EVENT'}} </button>
    </mat-dialog-actions>
</form>