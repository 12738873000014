import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';

@Component({
    selector: 'vex-theme-preview-dialog',
    templateUrl: './theme-preview-dialog.component.html',
    styleUrls: ['./theme-preview-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ThemePreviewDialogComponent implements OnInit {
    icClose = icClose;
    startCount = 1
    // @ViewChildren('emailDescription') emailDec : QueryList<ElementRef>
    @ViewChildren('emailDescription') emailDescription: QueryList<ElementRef>;
    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private dialogRef: MatDialogRef<ThemePreviewDialogComponent>,
        private cd : ChangeDetectorRef
    ) {
        console.log("_data", _data);
    }

    ngOnInit(): void {
        this.setDataByInnerHtml();
    }


    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.cd.detectChanges()
    }
    setDataByInnerHtml() {
        // for description
        let element: any = document.getElementById('description');
        element.innerHTML = this._data?.formData?.description;
        setTimeout(() => {
            let readyHtml = this._data?.details.challenges.map((item: any) => item?.emailInfo?.emailDescription);
            this.emailDescription.forEach((element, index) => {
                element.nativeElement.innerHTML = readyHtml[index];
            })
        }, 100);
    }


    // duration convart into week.
    getConvertedDataInWeekTeams(startDate, endDate, index) {
        // get one value in sec. 
        const ONE_DAY = 1000 * 60 * 60 * 24;

        // convert date info timestamp
        const startDateConvert = new Date(startDate);
        const endDateConvert = new Date(endDate);


        // console.log("startDateConvert",startDateConvert)
        // console.log("endDateConvert",endDateConvert)

        // get duration
        const duration = Math.abs(startDateConvert.getTime() - endDateConvert.getTime());


         var diff = duration / (1000 * 3600 * 24 *7);
        // get duration in days
        const days = Math.round(duration / ONE_DAY);

        // get week in rounded formate
        const week = Math.round(days / 7);
        

        console.log("duration" ,diff)
        // if(diff >= 28){
        //     return 'Month' + ' ' + (1 +index) + ' - ' + (diff + index+1);
        // }else{
            
        //     if(index >= 1){
        //         console.log("week in startCount",this.startCount)
        //         console.log("week in condition",week)
                
        //         this.startCount = this.startCount + 1
        //         return 'week' + ' ' + (this.startCount  ) + ' - ' + (this.startCount + diff) ;
        //     }
        //     else{
        //         this.startCount = diff + this.startCount
        //         return 'week' + ' ' + (this.startCount ) + ' - ' + (diff + this.startCount);
        //     }
          
        // }
        return "Activity"+' ' +  (index+1) 


        // return in formated way
        
    }
   
}