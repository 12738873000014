<!--tutorial-screen-->
<div class="tutorial-wrapper " #sidenav *ngIf="isTutorialChecked === 0">
    <div class="tutorial-box p-3" [ngStyle]="{'top': data.positionTop}">
        <h3>{{data.title}}</h3>
        <p>
            {{data.text}}
        </p>
        <div class="text-right">
            <button class="btn bg-white" mat-button (click)="redirect($event)">{{data.buttonName}}</button>
        </div>
    </div>
</div>
<!--tutorial-screen-->
