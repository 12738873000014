import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthServiceLocal } from '../services/auth.service';
import { LocalStoreService } from '../services/local-store.service';
import * as _ from 'lodash';    

@Component({
  selector: 'vex-onboard-dialog',
  templateUrl: './onboard-dialog.component.html',
  styleUrls: ['./onboard-dialog.component.scss']
})
export class OnboardDialogComponent implements OnInit {
  step : number = 1;
  welcomeText: string ="The actionable steps for culture and employee experience<br>aligned with your strategy."  
  surveyText: string = "When was your most recent company survey<br> conducted?"
  focusText:string = "What are the focus areas for your company?"
  personalizeText:string = "Let's personalize your actionable steps!"

  surveyMonth = [
    {text : "1-3 months ago", isSelected: false },
    {text : "4-6 months ago", isSelected: false },
    {text : "7-9 months ago", isSelected : false},
    {text : "10-12 months ago", isSelected : false}
]

  surveySelectedCount: any = [];
//focus 
  focusAreaforSelection: any[];
  focusSelectedCate: any;
  focusIsSelectionDisabled: boolean = true;
  focusSelectedCount: any = [];

  personalizeSelectedCount: any = [];
  selectedForPersonalize: any;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
  private dialogRef: MatDialogRef<OnboardDialogComponent>,
  private dialog: MatDialog,private snackbar : MatSnackBar,
  public auth : AuthServiceLocal ,public cd : ChangeDetectorRef,
  public store : LocalStoreService
  )
   {
    this.getKpilist();
    }

  ngOnInit(): void {     
  }
  

//survey
  onCategorySelectSurvey(value){
    this.surveySelectedCount =[]
    this.surveyMonth.forEach((element) =>{
      if(element.text == value.text){
          this.surveySelectedCount.push(element)
          element['isSelected'] = true;          
        }else{
          this.surveySelectedCount.push('')
            element['isSelected'] = false;  
        }
    })
}


getKpilist() {
  this.auth.getKpiAreaList().subscribe((res: any) => {
    if (res.status == 200) {
    
      res.data.kpis.forEach((element) => {
        element = element["isSelected"] = false;
      });

      this.focusAreaforSelection = res.data.kpis;

      let selectedStrategy: any = localStorage.getItem("kpiIds");
      selectedStrategy = selectedStrategy?.split(",");
      if (selectedStrategy != null) {

        console.log("focusAreaforSelection ===>",this.focusAreaforSelection)
        this.focusAreaforSelection.forEach((ele) => {
          selectedStrategy.forEach((element) => {
            if (element == ele.id) {
              this.focusSelectedCount.push(ele);
              ele.isSelected = true;
            }
          });
        });
      }
      console.log("tempArr ==> ", res.data.kpis);
     
    } else {
      this.snackbar.open(res.message, "Okay", { duration: 2000 });
    }
  });
}


// focus 
focusNext(){
  this.step =4
 
  let selectedCategoryId = [];

  const seletecedkpi = this.focusSelectedCount.map((ele: any) => {
    if (ele.isSelected === true) {
      selectedCategoryId.push(ele.id);
    }
  }); 

  localStorage.setItem("kpiIds", selectedCategoryId.toString());

  this.selectedForPersonalize = _.cloneDeep(this.focusSelectedCount);

  this.selectedForPersonalize.map((el)=>(el.isSelected = false ,{...el})); 
  
  console.log("this.selectedForPersonalize ==>",this.selectedForPersonalize);

  this.saveFocusArea(this.selectedForPersonalize)
  console.log(" this.getKpilist()",this.focusAreaforSelection)
}

focusOnCategorySelect(value) {
  this.focusAreaforSelection.forEach((element) => {
    if (element.name == value.name) {
      if (element.isSelected) {
        element["isSelected"] = false;
      } else {
        if (this.focusSelectedCount?.length < 3) {
          element["isSelected"] = true;
        }
      }
    }
  });

  this.focusSelectedCount = this.focusAreaforSelection.filter(
    (element) => element.isSelected
  );


  console.log("this.focusSelectedCount ===>",this.focusSelectedCount)

 
 
  this.cd.detectChanges();
}

    saveFocusArea(value){

      let tempFocusedIds  = value.map(ele => {return ele.id} )

      console.log("tempFocusedIds",tempFocusedIds)
      const postData = {
        "focusKpiIds" : tempFocusedIds.toString()
      };

    console.log("postdata", postData)
    this.auth.updatekpisStatus(postData).subscribe((response: any) => {
      if(response.status == 200 ){

      }else{
        this.snackbar.open(response.message,"Okay",{duration: 2000})
      }
    })
    }


  personalizeOnCategorySelect(value){
    this.personalizeSelectedCount = [];
    this.selectedForPersonalize.forEach((element) => {
      if (element.name == value.name) {
        this.personalizeSelectedCount.push(element);
        element["isSelected"] = true;
      } else {
        this.personalizeSelectedCount.push("");
        element["isSelected"] = false;
      }
    });

    console.log(" this.defaults", this.defaults);
  }


  personalizeBack(){
    this.step = 3
    this.personalizeSelectedCount =[]
  }

  completeOnboard(){
    let tempKpiIds;
    this.personalizeSelectedCount.forEach((ele) => {
      if (ele.isSelected == true) {
        return (tempKpiIds = ele.id);
      }
    });

    //  console.log("temp value ===>",temp)
      const postData = {
        "kpiIds" : tempKpiIds
      };

    console.log("postdata", postData)
    this.auth.updatekpisStatus(postData).subscribe((response: any) => {
      if(response.status == 200){
        this.updateOnBoard(tempKpiIds)
      }else{
        this.snackbar.open(response.message,"Okay",{duration:2000})
      }
    })

  }

  updateOnBoard(value){
    this.auth.updateOnboardStatus().subscribe((response: any) => {
      if(response.status == 200){
        const user = this.auth.getUser();
          user.isOnboard = 1;
          this.store.setItem('loggedUser', user);
          this.store.setItem('isSelectedKpi',value)
          this.dialogRef.close(value)
      }else{
        this.snackbar.open(response.message,"Okay",{duration:2000})
      }
    })
  }


}
