import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';

@Component({
  selector: 'vex-challenge-info-dailog',
  templateUrl: './challenge-info-dailog.component.html',
  styleUrls: ['./challenge-info-dailog.component.scss']
})
export class ChallengeInfoDailogComponent implements OnInit {
  lastChallengeDate: any;
  icClose = icClose
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ChallengeInfoDailogComponent>) {
    console.log('data in pop', data.lastChallengeDate)
    this.lastChallengeDate = data.lastChallengeDate
  }

  ngOnInit(): void {

  }

  continue(val) {
    this.dialogRef.close(val);
  }
  close() {
    this.dialogRef.close()
  }
}