import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChallengeInfoDailogComponent } from '../challenge-info-dailog/challenge-info-dailog.component';
import { AuthServiceLocal } from '../services/auth.service';
import icClose from '@iconify/icons-ic/twotone-close';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { es } from 'date-fns/locale';

@Component({
  selector: 'vex-select-challenge-type',
  templateUrl: './select-challenge-type.component.html',
  styleUrls: ['./select-challenge-type.component.scss']
})
export class SelectChallengeTypeComponent implements OnInit {
  singleChallenge: boolean = true;
  multiChallenge: boolean = false;
  form: FormGroup;

  isShowChallengeSelectMenu: boolean;
  isExistingChallenge: any;
  isBtnDisabled: Boolean = false
  isChallengeExist: number;

  icClose = icClose
  challengeData: any;
  isNextStartDate: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SelectChallengeTypeComponent>,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private auth: AuthServiceLocal,
    private fb: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      singleChallenge: '',
      multiChallenge: '',
    })

    this.challengeData = this.data.data
    this.isNextStartDate = this.data.isNextStartDate
    this.challengeData.isDirect = 1


    console.log("challenge data in selected challenge type ", this.data.isNextStartDate)
  }

  formData() {
    this.isBtnDisabled = true
    return new Promise<any>((resolve, reject) => {
      this.auth.getIsAnyCompanyChallengeExists().subscribe((response: any) => {
        if (response.status === 200) {
          if (response.data.isChallengeExists == 1) {
            this.dialog.open(ChallengeInfoDailogComponent, {
              width: '450px',
              data: {
                lastChallengeDate: response.data.lastChallengeDate
              }

            }).afterClosed().subscribe(response => {
              console.log("response in select challenge type ", response)
              if (response == 'cancel') {
                console.log("form data in select challenge type ", this.form.value)
                this.isShowChallengeSelectMenu = true
                this.isChallengeExist = 1
                this.challengeData.selectedOption = "1"

                if(this.singleChallenge === true ){
                  this.addMangerTemplate(this.challengeData,'singleChallenge')

                }else{
                  this.addMangerTemplate(this.challengeData,'multiChallenge')
                }             
              }
              else if(response == 'continue'){
                this.isShowChallengeSelectMenu = true
                this.isChallengeExist = 2
                this.challengeData.selectedOption = "2"

                this.challengeData.startDate = this.isNextStartDate
                if(this.singleChallenge === true ){
                  this.addMangerTemplate(this.challengeData,'singleChallenge')
                }else{
                  this.addMangerTemplate(this.challengeData,'multiChallenge')
                }
              }
              else{
                this.isBtnDisabled = false
              }
            })
          }
          else {
            console.log("form data in select challenge type ", this.form.value)
            this.isShowChallengeSelectMenu = true
            this.isChallengeExist = 2
            this.challengeData.startDate = this.isNextStartDate
            if(this.singleChallenge === true ){
              this.addMangerTemplate(this.challengeData,'singleChallenge')

            }else{
              this.addMangerTemplate(this.challengeData,'multiChallenge')

            }

            // this.addMangerTemplate(this.challengeData)

            console.log("is next start date in select challenge type", this.isNextStartDate)
            console.log("challenge data if user click on cancel ", this.challengeData)
           
          }
        }
      });
  })
}


changeValue(val, type) {
  console.log("val in change ", val)
  console.log("type in change", type)
  this.data.data.isSingle = 1
  if (type == 'multiChallenge') {
    this.singleChallenge = false
    this.data.data.isSingle = 0
    console.log("challenge data in selected challenge type multiple ", this.data.data.isSingle)
  } else {
    this.multiChallenge = false
    this.data.data.isSingle = 1
    console.log("challenge data in selected challenge type single ", this.data.data.isSingle)
  }
}


addMangerTemplate(payload,challengeType) {
  console.log("payload for add challenge in pop up", payload, challengeType)

  this.auth.addManagerChallenges(payload).subscribe((response: any) => {
    if (response.status === 200) {
      this.snackbar.open(response.message);
      this.dialog.closeAll()
      this.router.navigate(['/launch'], { queryParams: { challenge: challengeType}})
    }{
     
      this.dialog.closeAll()
    }
  })
}

}