import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-on-boarding-notification',
  templateUrl: './on-boarding-notification.component.html',
  styleUrls: ['./on-boarding-notification.component.scss']
})
export class OnBoardingNotificationComponent implements OnInit {


constructor(
    @Inject(MAT_DIALOG_DATA) public  data: any,
    private dialogRef: MatDialogRef<OnBoardingNotificationComponent>,
  ) { }

  ngOnInit(): void {
  
    console.log("data ===>",this.data)
  }




}
