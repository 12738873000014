import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import { BehaviorSubject } from 'rxjs';
import { PraiseColleagueService } from 'src/app/pages/praise-colleague/praise-colleague.service';

@Component({
  selector: 'vex-parise-search-box',
  templateUrl: './parise-search-box.component.html',
  styleUrls: ['./parise-search-box.component.scss']
})
export class PariseSearchBoxComponent implements OnInit {

  icClose = icClose;
  searchText: string;


  colleague: any;
  teamOrColleague: any;
  demo: { id: any; name: any; };
  selectedTeamOrColleagues: any = [];
  selectedTeam: { id: any; name: any; };
  teamOrColleagueName: any;
  updateArrayIndex: any;
  recievedArr: any;






  constructor(private dialogRef: MatDialogRef<PariseSearchBoxComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any, private praiseColleague: PraiseColleagueService
  ) {

    this.praiseColleague.teamOrMember.subscribe((data) => {
      // console.log("team", data)
      this.colleague = data;
    })
    
 
  }

  ngOnInit(): void {
  }

  checkCheckBoxvalue(val) {
    console.log("when checked ", val)
    console.log("in dailog data ", this.colleague)


  }

  addMember() {
    // this.praiseColleague.teamOrMember.next(this.colleague)
    this.dialogRef.close({ newChanges: true })

  }
  close() {

  const newArray =  this.colleague.map((element)=>({...element,checked:false}))
  this.praiseColleague.teamOrMember.next(newArray)
    this.dialogRef.close({ newChanges: false }
      )
  }




}



