<mat-dialog-content>
    <div class="w-full mb-5">
    
    <div *ngIf="this.emojiType == 1">
        <img class="emoji_icon" src="../../assets/img/praise-colleague/chocolate.png">
    </div>    
    <div *ngIf="this.emojiType == 2">
        <img class="emoji_icon" src="../../assets/img/praise-colleague/hand.png">
    </div>    
    <div *ngIf="this.emojiType == 3">
        <img class="emoji_icon" src="../../assets/img/praise-colleague/party.png">
    </div>    
    <div *ngIf="this.emojiType == 4">
        <img class="emoji_icon" src="../../assets/img/praise-colleague/sad.png">
    </div>    
    <div *ngIf="this.emojiType == 5">
        <img class="emoji_icon" src="../../assets/img/praise-colleague/star.png">
    </div>    


     <h2 class="mt-2 emoji-title ">Your praise has been sent.<br> Great job!</h2>
     <!-- <h4 class="text-center">Your {{this.emojiTitle }} emoji sent.</h4>  -->
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="center" >
    <button mat-raised-button class="mb-2 emoji text-contrast-white" mat-dialog-close tabindex="-1">Ok</button>
</mat-dialog-actions> -->
