// export const ApiEndPointUrl = 'https://api.empida.com/api/v5.37'; // prod

export const ApiEndPointUrl = 'https://apidev.empida.com/api/v5.37'; // stage

export enum Pattern {
    NumberWithDecimal = '^0$|^[1-9]\\d*$|^\\.\\d+$|^0\\.\\d*$|^[1-9]\\d*\\.\\d*$',
    Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
    Mobile = '[0-9]+',
    MobileMinLength = 10,
    Password = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$', // '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$'.
    Numbers = '^[1-9]*$',
}

export enum language {
    English = 'en',
    Norway = 'nb'
}

// User Status
export enum UserStatus {
    Inactive = 0,
    Active = 1,
    Block = 2
}


export enum questionType {
    MultipleChoice = 1,
    Answerable = 2
}


export enum leaderBoardType {
    pointLeaderBoard = 1,
    percentageLeaderBoard = 2
}



export enum wellnessType {
    Quiz = 1,
    Answer = 2
}
export enum challengeType {
    Individual = 1,
    Team = 2,
    Self = 3,
}


export enum challengesPrivacyType {
    Public = 1,
    Private = 2,
}

export enum challengesStatus {
    Active = 1,
    Delete = 2,
}

export enum invitationType {
    User = 1,
    Team = 2,
}

export enum booleanValue {
    No = 0,
    Yes = 1
}

export enum days {
    mon = 0,
    tue = 1,
    wed = 2,
    thu = 3,
    fri = 4,

}

//

export enum challengeCategory {
    STEP = 1,
    WATER = 2,
    COUNTING = 3,
    POINTS = 4,
    SLEEP = 5,
    SCREEN_TIME = 6,
    GOAL = 7,
    QUIZ = 8,
    PUSHUP_COUNTING = 9,
    SOCIAL_BREAK = 10,
    GRATITUDE = 11,
    MEDITATION = 12,
    COMBINATION = 13,
    LANGUAGE_CHALLENGE = 14,
    CO2_CHALLENGE = 15,
    NETWORK_CHALLENGE = 16,
    STAIRS = 17,
    SO_GR = 18,
    LA_COM = 19,
    PICTURE_CHALLENGE = 20
}

export enum currency {
    US_Dollar = 'USD',
    Euro = 'EUR',
    Norwegian = 'NOK',
    Japanese_Yen = 'JPY',
    Pound_Sterling = 'GBP',
    Australian_Dollar = 'AUD',
    Canadian_Dollar = 'CAD',
    Swiss_Franc = 'CHF',
    Chinese_Renminbi = 'CNH',
}


export enum monthFilter {
    JAN = 1,
    FEB = 2,
    MAR = 3,
    APR = 4,
    MAY = 5,
    JUN = 6,
    JLY = 7,
    AUG = 8,
    SEPT = 9,
    OCT = 10,
    NOV = 11,
    DEC = 12
}

export const monthFilterList = [
    { name: 'JAN', value: monthFilter.JAN },
    { name: 'FEB', value: monthFilter.FEB },
    { name: 'MAR', value: monthFilter.MAR },
    { name: 'APR', value: monthFilter.APR },
    { name: 'MAY', value: monthFilter.MAY },
    { name: 'JUN', value: monthFilter.JUN },
    { name: 'JLY', value: monthFilter.JLY },
    { name: 'AUG', value: monthFilter.AUG },
    { name: 'SEPT', value: monthFilter.SEPT },
    { name: 'OCT', value: monthFilter.OCT },
    { name: 'NOV', value: monthFilter.NOV },
    { name: 'DEC', value: monthFilter.DEC },
]

export const feedbackQuestionTypes = [
    { id: '1', title: 'Single Choice' },
    { id: '2', title: 'Open Ended' },
]

export enum slackEventData {
    SLACK_INTEGRATION = 0,
    COMPANY_CHALLENGE_STARTS = 1,
    TEAM_CHALLENGE_STARTS = 2,
    COMPANY_CHALLENGE_ENDS = 3,
    TEAM_CHALLENGE_ENDS = 4,
    DONATION_GOAL_STARTS = 5,
    DONATION_GOAL_REACHED = 6,
    TEAM_POSITION = 7,
    MANAGER_SENT_EMO = 8,
    REWARD_LEADERBOARD = 9,
    NEW_WELLNESS_CATEGORY_LAUNCHED = 10,
}

export const slackEventList = [
    { name: 'SLACK INTEGRATION', value: slackEventData.SLACK_INTEGRATION },
    { name: 'COMPANY ACTION STARTS', value: slackEventData.COMPANY_CHALLENGE_STARTS },
    { name: 'TEAM ACTION STARTS', value: slackEventData.TEAM_CHALLENGE_STARTS },
    { name: 'COMPANY ACTION ENDS', value: slackEventData.COMPANY_CHALLENGE_ENDS },
    { name: 'TEAM ACTION ENDS', value: slackEventData.TEAM_CHALLENGE_ENDS },
    { name: 'DONATION GOAL STARTS', value: slackEventData.DONATION_GOAL_STARTS },
    { name: 'DONATION GOAL REACHED ', value: slackEventData.DONATION_GOAL_REACHED },
    { name: 'TEAM_POSITION', value: slackEventData.TEAM_POSITION },
    { name: 'MANAGER SENT EMO', value: slackEventData.MANAGER_SENT_EMO },
    { name: 'REWARD LEADERBOARD', value: slackEventData.REWARD_LEADERBOARD },
    { name: 'NEW WELLNESS CATEGORY LAUNCHED', value: slackEventData.NEW_WELLNESS_CATEGORY_LAUNCHED },

]

export enum slackEventDataStatus {
    ALLOWED = 1,
    NOT_ALLOWED = 2
}
export const slackEventStatus = [
    { name: 'ALLOWED', value: slackEventDataStatus.ALLOWED },
    { name: 'NOT ALLOWED ', value: slackEventDataStatus.NOT_ALLOWED },

]


export enum roleType {
    Admin = 1,
    Manager = 2
}


export enum Gender {
    male = 1,
    female = 2
}

export const challengeCategoryList = [
    { name: 'Step', value: challengeCategory.STEP },
    { name: 'Water', value: challengeCategory.WATER },
    { name: 'Counting', value: challengeCategory.COUNTING },
    { name: 'Points', value: challengeCategory.POINTS },
    { name: 'Sleep', value: challengeCategory.SLEEP },
    { name: 'Screen Time', value: challengeCategory.SCREEN_TIME },
    { name: 'Goal', value: challengeCategory.GOAL },
    { name: 'Quiz', value: challengeCategory.QUIZ },
    { name: 'Pushup counting', value: challengeCategory.PUSHUP_COUNTING },
    { name: 'Gratitude', value: challengeCategory.GRATITUDE },
    { name: 'Social Break', value: challengeCategory.SOCIAL_BREAK },
    { name: 'Meditation', value: challengeCategory.MEDITATION },
    { name: 'Combination', value: challengeCategory.COMBINATION },
    { name: 'Co2', value: challengeCategory.CO2_CHALLENGE },
    { name: 'Language', value: challengeCategory.LANGUAGE_CHALLENGE },
    { name: 'Network challenge', value: challengeCategory.NETWORK_CHALLENGE },
    { name: 'Stairs', value: challengeCategory.STAIRS },
    { name: 'SO_GR', value: challengeCategory.SO_GR },
    { name: 'LA_COM', value: challengeCategory.LA_COM },
    { name: 'PICTURE_CHALLENGE', value: challengeCategory.PICTURE_CHALLENGE }
];

export const defaultChallengeCategoryList = [
    { name: 'Step', value: challengeCategory.STEP },
    { name: 'Water', value: challengeCategory.WATER },
    { name: 'Counting', value: challengeCategory.COUNTING },
    { name: 'Points', value: challengeCategory.POINTS },
    { name: 'Sleep', value: challengeCategory.SLEEP },
    { name: 'Screen Time', value: challengeCategory.SCREEN_TIME },
    { name: 'Goal', value: challengeCategory.GOAL },
    { name: 'Quiz', value: challengeCategory.QUIZ },
    { name: 'Pushup counting', value: challengeCategory.PUSHUP_COUNTING },
    { name: 'Gratitude', value: challengeCategory.GRATITUDE },
    { name: 'Social Break', value: challengeCategory.SOCIAL_BREAK },
    { name: 'Meditation', value: challengeCategory.MEDITATION },
    { name: 'Combination', value: challengeCategory.COMBINATION },
    { name: 'Co2', value: challengeCategory.CO2_CHALLENGE },
    { name: 'Language', value: challengeCategory.LANGUAGE_CHALLENGE },
    { name: 'Network challenge', value: challengeCategory.NETWORK_CHALLENGE },
    { name: 'Stairs', value: challengeCategory.STAIRS },
    { name: 'SO_GR', value: challengeCategory.SO_GR },
    { name: 'LA_COM', value: challengeCategory.LA_COM },
    { name: 'PICTURE_CHALLENGE', value: challengeCategory.PICTURE_CHALLENGE }
];

export enum programStatus {
    ongoing = 1,
    expired = 2,
    upcoming = 3
}

export const challengeImagesList = [
    {
        type: challengeCategory.STEP,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434503110.png'
    },
    {
        type: challengeCategory.WATER,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434535417.png'
    },
    {
        type: challengeCategory.COUNTING,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434562313.png'
    },
    {
        type: challengeCategory.POINTS,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434591589.png'
    },
    {
        type: challengeCategory.SLEEP,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434618109.png'
    },
    {
        type: challengeCategory.SCREEN_TIME,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434649402.png'
    },
    {
        type: challengeCategory.GOAL,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434681561.png'
    },
    {
        type: challengeCategory.QUIZ,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434715184.png'
    },
    {
        type: challengeCategory.PUSHUP_COUNTING,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434743256.png'
    },
    {
        type: challengeCategory.GRATITUDE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434771115.png'
    },
    {
        type: challengeCategory.SOCIAL_BREAK,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434809459.png'
    },
    {
        type: challengeCategory.MEDITATION,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434836740.png'
    },
    {
        type: challengeCategory.COMBINATION,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434864643.png'
    },
    {
        type: challengeCategory.CO2_CHALLENGE,
        imageUrl: 'https://empida.s3.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434430404.png'
    },
    {
        type: challengeCategory.LANGUAGE_CHALLENGE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434896385.png'
    },
    {
        type: challengeCategory.NETWORK_CHALLENGE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434919149.png'
    },
    {
        type: challengeCategory.STAIRS,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434956086.png'
    },
    {
        type: challengeCategory.LA_COM,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434864643.png'
    },
    {
        type: challengeCategory.SO_GR,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434809459.png'
    },
    {
        type: challengeCategory.PICTURE_CHALLENGE,
        imageUrl: 'https://empida.s3.us-west-2.amazonaws.com/challenge-image/challenge-image_365b9289-c59d-11ea-9b66-0eabad8f0426_1642434809459.png'
    }
];

export enum Module {

    Dashboard = 1,
    Challenges = 2,
    Teams = 16,
    Employees = 4,
    Rewards = 5,
    Ngo = 6,
    Roles = 7,
    SendNotifications = 8,
    SlackIntegration = 9,
    Events = 12,
    Recognitionfeature = 10,
    WellnessCategories = 11,
    department = 12,
    Coaching = 13,
    monthlyThemes = 14,
    managerAi = 15
}


export const languageList = [
    { name: 'Norwegian', value: 'NO' },
    { name: 'English', value: 'YES' }
]


export const launchWeekList = [
    { name: 'Week 1', value: 1 },
    { name: 'Week 2', value: 2 },
    { name: 'Week 3', value: 3 },
    { name: 'Week 4', value: 4 }
]

export const surveyListDuration = [
    { name: '5 Minutes', value: 0.1 },
    { name: '10 Minutes', value: 0.2 },
    { name: '15 Minutes', value: 0.25 },
    { name: '20 Minutes', value: 0.33 },
    { name: '1 Hour', value: 1 },
    { name: '1 Day', value: 24 },
    { name: '1 Week', value: 100 },
    { name: '2 Weeks', value: 101 },
    { name: '3 Weeks', value: 102 },
    { name: '4 Weeks', value: 103 },
    { name: '5 Weeks', value: 104 },
    { name: '6 Weeks', value: 105 },
    { name: '1 Month', value: 200 },
    { name: '2 Months', value: 201 },
    { name: '3 Months', value: 202 },
    { name: '4 Months', value: 203 },
    { name: '5 Months', value: 204 },
    { name: '6 Months', value: 205 }
];

export const companySizeData = [
    { name: "1-50", isDisabled: true },
    { name: "51-75", isDisabled: true },
    { name: "76-200", isDisabled: true },
    { name: "201-500", isDisabled: true },
    { name: "501-5000", isDisabled: false },
    { name: "5001-9999", isDisabled: true },
    { name: "10000+", isDisabled: true },
];


export const yearFilter = [
    { name: '2022', value: 2022 },
    { name: '2023', value: 2023 },
    { name: '2024', value: 2024 },
]

export const emailDomains = [
    "finn.no",
    "schibsted.com",
    "mailinator.com",
    // 'gmail.com',
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "hotmail.co.uk",
    "hotmail.fr",
    "msn.com",
    "yahoo.fr",
    "wanadoo.fr",
    "orange.fr",
    "comcast.net",
    // 'yahoo.co.uk',
    // 'yahoo.com.br',
    // 'yahoo.co.in',
    "live.com",
    "rediffmail.com",
    "free.fr",
    "gmx.de",
    "web.de",
    "yandex.ru",
    "ymail.com",
    "libero.it",
    // 'outlook.com',
    "uol.com.br",
    "bol.com.br",
    "mail.ru",
    "cox.net",
    "hotmail.it",
    "sbcglobal.net",
    "sfr.fr",
    "live.fr",
    "verizon.net",
    "live.co.uk",
    "googlemail.com",
    "yahoo.es",
    "ig.com.br",
    "live.nl",
    "bigpond.com",
    "terra.com.br",
    "yahoo.it",
    "neuf.fr",
    "yahoo.de",
    "alice.it",
    "rocketmail.com",
    "att.net",
    "laposte.net",
    "facebook.com",
    "bellsouth.net",
    "yahoo.in",
    "hotmail.es",
    "charter.net",
    "yahoo.ca",
    "yahoo.com.au",
    "rambler.ru",
    "hotmail.de",
    "tiscali.it",
    "shaw.ca",
    "yahoo.co.jp",
    "sky.com",
    "earthlink.net",
    "optonline.net",
    "freenet.de",
    "t-online.de",
    "aliceadsl.fr",
    "virgilio.it",
    "home.nl",
    "qq.com",
    "telenet.be",
    "me.com",
    "yahoo.com.ar",
    "tiscali.co.uk",
    "yahoo.com.mx",
    "voila.fr",
    "gmx.net",
    "mail.com",
    "planet.nl",
    "tin.it",
    "live.it",
    "ntlworld.com",
    "arcor.de",
    "yahoo.co.id",
    "frontiernet.net",
    "hetnet.nl",
    "live.com.au",
    "yahoo.com.sg",
    "zonnet.nl",
    "club-internet.fr",
    "juno.com",
    "optusnet.com.au",
    "blueyonder.co.uk",
    "bluewin.ch",
    "skynet.be",
    "sympatico.ca",
    "windstream.net",
    "mac.com",
    "centurytel.net",
    "chello.nl",
    "live.ca",
    "aim.com",
    "bigpond.net.au",
];


export const howItsWorks = [
    {
        title: "Select a focus area",
        description: "",
        image: "../../assets/img/inivated-user/focusArea.svg",
    },
    {
        title: "Pick an action",
        description: "",
        image: "../../assets/img/inivated-user/pickAction.svg",
    },
    {
        title: "We’ll nudge your employees",
        description: "",
        image: "../../assets/img/inivated-user/nudge.svg",
    },
    {
        title: "See real results",
        description: "",
        image: "../../assets/img/inivated-user/realResults.svg",
    },
];

export const preferredChannel = [
    { title: `We'll inform your teammates about the action` },
    { title: "Nudge them to complete" },
    { title: "Send you status reports each Friday" },
];

export const focusCategory = [
    {
        name: "Select Focus",
        image: "../../assets/img/inivated-user/selectFocus.svg",
        desc: `Choose a key area for growth and development.`,
    },
    {
        name: "Assign Action",
        image: "../../assets/img/inivated-user/assignAction.svg",
        desc: `Pick an action for your team aligned with your goals.`,
    },
    {
        name: "Set Channel",
        image: "../../assets/img/inivated-user/Channel.png",
        desc: `Opt for Slack, email, etc., to inform and nudge your team.`,
    },
    {
        name: "Weekly Insights",
        image: "../../assets/img/inivated-user/weeklyInsights.svg",
        desc: `Receive a snapshot of progress every Friday.`,
    },
];


export const weeklyAction = [
    {
        id: 0,
        shortTitle: "mon",
        title: "Monday",
        isSelected: false,
        isEdit: false,
        notification: "",
    },
    {
        id: 1,
        shortTitle: "tue",
        title: "Tuesday",
        isSelected: false,
        isEdit: false,
        notification: "",
    },
    {
        id: 2,
        shortTitle: "wed",
        title: "Wednesday",
        isSelected: false,
        isEdit: false,
        notification: "",
    },
    {
        id: 3,
        shortTitle: "thu",
        title: "Thursday",
        isSelected: false,
        isEdit: false,
        notification: "",
    },
    {
        id: 4,
        shortTitle: "fri",
        title: "Friday",
        isSelected: false,
        isEdit: false,
        notification: "",
    },
];



export const teamSize = [
    {
        size: "1-10",
        isSelected: false,
        image: "svgTeamSize1",
    },
    {
        size: "11-25",
        isSelected: false,
        image: "svgTeamSize2",
    },
    {
        size: "26-49",
        isSelected: false,
        image: "svgTeamSize3",
    },
    {
        size: "50+",
        isSelected: false,
        image: "svgTeamSize4",
    },

];


